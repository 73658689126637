import axios from 'axios';
import { useRoutes, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

// layouts
import MainLayout from './layouts/MainLayout';

import Login from './pages/login/Login';
import Dashboard from './pages/main/Dashboard';
import BackpayDashboard from './pages/main/CustomerRevenueDashboard';
import DataStudio from './pages/main/ConflictsDataStudio';
import Historical from './pages/main/Historical';
import AssetDetails from './pages/main/AssetDetails';
import BackPay from './pages/main/BackPay';
import Customers from './pages/main/Customers';
import RPMStats from './pages/main/RPMStats';
// import Offboarded from './pages/main/Offboarded';
import NotFound from './pages/Page404';

import { useGlobalState } from './context/global/globalContext';

// ----------------------------------------------------------------------

export default function Router() {
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalState();

  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      const temp = data.user;
      localStorage.setItem('currentUser', JSON.stringify(temp));
      dispatch({ type: 'UPDATE_USER', payload: temp });
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`);
    } catch (err) {
      console.log('Login Required');
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    getUser();
    // eslint-disable-next-line
  }, []);

  return useRoutes([
    {
      path: '/main',
      element: currentUser ? <MainLayout /> : <Navigate to="/login" replace />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'backpaydashboard', element: <BackpayDashboard /> },
        { path: 'datastudio', element: <DataStudio /> },
        { path: 'historical', element: <Historical /> },
        { path: 'assetdetails', element: <AssetDetails /> },
        { path: 'backpay', element: <BackPay /> },
        { path: 'rpmstats', element: <RPMStats /> },
        { path: 'customers', element: <Customers /> },
        // { path: 'offboardedcustomers', element: <Offboarded /> },
      ],
    },
    {
      path: 'login',
      element: currentUser ? <Navigate to="/main/dashboard" replace /> : <Login />,
    },
    {
      path: '/',
      element: currentUser ? <Navigate to="/main/dashboard" replace /> : <Login />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
}
