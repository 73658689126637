import PropTypes from 'prop-types';

import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';


const Notification = (props) => {
  // eslint-disable-next-line
  const { notify, setNotify } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  return (
    <Snackbar
      // eslint-disable-next-line
      open={notify.isOpen}
      autoHideDuration={1000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert
        // eslint-disable-next-line
        severity={notify.type}
        onClose={handleClose}
      >
        {
          // eslint-disable-next-line
          notify.message
        }
      </Alert>
    </Snackbar>
  );
}

Notification.propTypes = {
  notify: PropTypes.object,
  setNotify: PropTypes.func
};

export default Notification;