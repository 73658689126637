import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const DatePicker = (props) => {
  const { name, label, value, onChange } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/DD/YYYY"
        name={name}
        value={value}
        onChange={(date) => onChange(convertToDefEventPara(name, date.$d))}
        renderInput={(params) => (
          <TextField
            sx={{
              width: '80%',
              padding: '8px',
              margin: '1px',
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatePicker;