import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// components
import { useForm, Form } from '../sections/form/useForm';
import { useCustomerNames } from '../../services/customerService';

import { FormInput, FormSelect, FormDatePicker, FormButton } from '../sections/form/controls';

const initialFValues = {
  id: 0,
  created_date: new Date(),
  last_historical_update: new Date(),
  main_exist: '',
  conflict_status: '',
  approx_daily_views: '',
  approx_daily_views_in_conflict: '',
  active_claims: '',
  ownership_last_updated: new Date(),
  other_ownership_last_updated: new Date(),
  ownership_origination: '',
  customer: '',
  silo: '',
  asset_id: '',
  conflicting_owner: '',
  conflicting_country_code: '',
  asset_type: '',
  custom_id: '',
  isrc: '',
  upc: '',
  artist: '',
  asset_title: '',
  album: '',
  label: '',
  asset_label: '',
  constituent_asset_id: '',
  match_policy: '',
  is_merged: '',
  total_views_in_conflict: '',
  estimated_backpay: '',
  metadata_origination: '',
  grid: '',
};

const siloItems = [
  { id: 'AdShare MG', title: 'AdShare MG' },
  { id: 'AdShare Third Party', title: 'AdShare Third Party' },
];

const AssetDetailsForm = (props) => {
  const { addAssetDetail, assetDetailNew } = props;


  const initialCustomers = { dataset: [{ id: 'Loading..', title: 'Loading..' }] };
  const [customerList, setCustomerList] = useState(initialCustomers);

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      (x) => {
        const pmx = primer(x[field])
        return pmx
      } :
      (x) => {
        const pmx = x[field]
        return pmx
      };

    reverse = !reverse ? 1 : -1;

    return (a, b) => {
      const ae = (a = key(a), b = key(b), reverse * ((a > b) - (b > a)));
      return ae
    }
  }

  const customernameSuccess = (data) => {
    if (data) {
      const clist = {
        dataset: [
          ...new Set(
            data.map((item) => {
              return { id: item.id, title: item.id };
            })
          ),
        ],
      };
      clist.dataset.sort(sort_by('id', false, (a) => a.toUpperCase()));
      setCustomerList(clist);
    }
  };

  // eslint-disable-next-line
  const { data: CustomerNames } = useCustomerNames(customernameSuccess);

  const validate = (fieldValues = values) => {
    // MORE VALIDATION REQUIRED, POSTPONED FOR LATER
    /* eslint-disable-next-line prefer-const */
    let temp = { ...errors };
    if ('asset_id' in fieldValues)
      temp.asset_id = fieldValues.asset_id.length > 5 ? '' : 'Please enter a valid Asset ID.';
    if ('silo' in fieldValues) temp.silo = fieldValues.silo.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // eslint-disable-next-line
      addAssetDetail(values, resetForm);
    }
  };

  useEffect(() => {
    if (assetDetailNew !== null) {
      // eslint-disable-next-line
      setValues({
        ...assetDetailNew,
      });
    }
    // eslint-disable-next-line
  }, [assetDetailNew]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container minWidth="300" width="280">
        <Grid item sm={6}>
          <FormDatePicker
            name="created_date"
            label="Created Date"
            value={values.created_date}
            onChange={handleInputChange}
          />

          <FormInput
            name="main_exist"
            label="Main Exist"
            value={values.main_exist}
            onChange={handleInputChange}
            error={errors.main_exist}
          />

          <FormInput
            name="approx_daily_views"
            label="Approx Daily Views"
            value={values.approx_daily_views}
            onChange={handleInputChange}
            error={errors.approx_daily_views}
          />

          <FormInput
            name="active_claims"
            label="Active Claims"
            value={values.active_claims}
            onChange={handleInputChange}
            error={errors.active_claims}
          />

          <FormDatePicker
            name="other_ownership_last_updated"
            label="Other Ownership Last Updated"
            value={values.other_ownership_last_updated}
            onChange={handleInputChange}
          />

          <FormSelect
            name="customer"
            label="Customer"
            value={values.customer}
            options={customerList.dataset}
            onChange={handleInputChange}
            error={errors.customer}
          />

          <FormInput
            name="asset_id"
            label="Asset ID"
            value={values.asset_id}
            onChange={handleInputChange}
            error={errors.asset_id}
          />

          <FormInput
            name="conflicting_country_code"
            label="Conflicting Country Code"
            value={values.conflicting_country_code}
            onChange={handleInputChange}
            error={errors.conflicting_country_code}
          />

          <FormInput
            name="custom_id"
            label="Custom ID"
            value={values.custom_id}
            onChange={handleInputChange}
            error={errors.custom_id}
          />

          <FormInput name="upc" label="UPC" value={values.upc} onChange={handleInputChange} error={errors.upc} />

          <FormInput
            name="asset_title"
            label="Asset Title"
            value={values.asset_title}
            onChange={handleInputChange}
            error={errors.asset_title}
          />

          <FormInput
            name="label"
            label="Label"
            value={values.label}
            onChange={handleInputChange}
            error={errors.label}
          />

          <FormInput
            name="constituent_asset_id"
            label="Constituent Asset ID"
            value={values.constituent_asset_id}
            onChange={handleInputChange}
            error={errors.constituent_asset_id}
          />

          <FormInput
            name="is_merged"
            label="Is Merged"
            value={values.is_merged}
            onChange={handleInputChange}
            error={errors.is_merged}
          />

          <FormInput
            name="estimated_backpay"
            label="Estimated Backpay"
            value={values.estimated_backpay}
            onChange={handleInputChange}
            error={errors.estimated_backpay}
          />
        </Grid>
        <Grid item sm={6}>
          <FormDatePicker
            name="last_historical_update"
            label="Last Historical Update"
            value={values.last_historical_update}
            onChange={handleInputChange}
          />

          <FormInput
            name="conflict_status"
            label="Conflict Status"
            value={values.conflict_status}
            onChange={handleInputChange}
            error={errors.conflict_status}
          />

          <FormInput
            name="approx_daily_views_in_conflict"
            label="Approx Daily Views In Conflict"
            value={values.approx_daily_views_in_conflict}
            onChange={handleInputChange}
            error={errors.approx_daily_views_in_conflict}
          />

          <FormDatePicker
            name="ownership_last_updated"
            label="Ownership Last Updated"
            value={values.ownership_last_updated}
            onChange={handleInputChange}
          />

          <FormInput
            name="ownership_origination"
            label="Ownership Origination"
            value={values.ownership_origination}
            onChange={handleInputChange}
            error={errors.ownership_origination}
          />

          <FormSelect
            name="silo"
            label="Silo"
            value={values.silo}
            onChange={handleInputChange}
            options={siloItems}
            error={errors.silo}
          />

          <FormInput
            name="conflicting_owner"
            label="Conflicting Owner"
            value={values.conflicting_owner}
            onChange={handleInputChange}
            error={errors.conflicting_owner}
          />

          <FormInput
            name="asset_type"
            label="Asset Type"
            value={values.asset_type}
            onChange={handleInputChange}
            error={errors.asset_type}
          />

          <FormInput name="isrc" label="ISRC" value={values.isrc} onChange={handleInputChange} error={errors.isrc} />

          <FormInput
            name="artist"
            label="Artist"
            value={values.artist}
            onChange={handleInputChange}
            error={errors.artist}
          />

          <FormInput
            name="album"
            label="Album"
            value={values.album}
            onChange={handleInputChange}
            error={errors.album}
          />

          <FormInput
            name="asset_label"
            label="Asset Label"
            value={values.asset_label}
            onChange={handleInputChange}
            error={errors.asset_label}
          />

          <FormInput
            name="match_policy"
            label="Match Policy"
            value={values.match_policy}
            onChange={handleInputChange}
            error={errors.match_policy}
          />

          <FormInput
            name="total_views_in_conflict"
            label="Total Views In Conflict"
            value={values.total_views_in_conflict}
            onChange={handleInputChange}
            error={errors.total_views_in_conflict}
          />

          <FormInput
            name="metadata_origination"
            label="Metadata Origination"
            value={values.metadata_origination}
            onChange={handleInputChange}
            error={errors.metadata_origination}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <FormButton
              type="submit"
              text="Insert New Record"
              sx={{
                marginTop: 2,
                marginLeft: -12,
                marginRight: 4,
              }}
            />
          </Grid>
          <Grid item>
            <FormButton
              text="Start Over"
              color="grey"
              onClick={resetForm}
              sx={{
                marginTop: 2,
                marginLeft: 8,
                marginRight: 5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

AssetDetailsForm.propTypes = {
  addAssetDetail: PropTypes.func,
  assetDetailNew: PropTypes.object,
};

export default AssetDetailsForm;