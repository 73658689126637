import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { getRPMs, insertRPMs, bulkInsertRPMs, updateRPMs, deleteRPMs } from '../api/rpmStatsAPI';

export const useRPM = (onSuccess) => {
  return useQuery(['rpm-data'], getRPMs, {
    initialData: [{ id: 0 }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useAddRPM = (addRPMSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((RPM) => insertRPMs(RPM), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addRPMSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['rpm-data']);
    },
  });
};

export const useAddRPMBulk = (addRPMSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((RPMs) => bulkInsertRPMs(RPMs), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addRPMSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['rpm-data']);
    },
  });
};

export const useUpdateRPM = (editRPMSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((RPM) => updateRPMs(RPM), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      editRPMSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['rpm-data']);
    },
  });
};

export const useDeleteRPM = (deleteRPMSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteRPMs(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      deleteRPMSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['rpm-data']);
    },
  });
};
