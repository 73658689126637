import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getHistoricals,
  insertHistoricals,
  bulkInsertHistoricals,
  updateHistoricals,
  deleteHistoricals,
  countHistoricalConditional,
  countHistoricals,
  // getPTMPeriod,
  // getPTMConflicts,
  // getPTMResolved,
  // getPTMReturnedConflicts,
  getFavorConflicts,
  getFavorThirdParty,
  getFavorAdShare,
  getFavorOther,
  getHighestCPCount,
  getResolveRadar,
  getPeriods,
  getAllPTMPeriod,
  getAllPTMConflicts,
  getAllPTMReturnedConflicts,
  getAllPTMResolved
} from '../api/historicalAPI';

export const useHistorical = (onSuccess, { page, pageSize }) => {
  const thispage = page;
  const thispageSize = pageSize;

  return useQuery(['historical-data', page], () => getHistoricals({ pageNumber: thispage, pageLimit: thispageSize }), {
    initialData: { rows: [{ id: 0 }] },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useAddHistorical = (addHistoricalSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((historical) => insertHistoricals(historical), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addHistoricalSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['historical-data']);
    },
  });
};

export const useAddHistoricalBulk = (addHistoricalSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((historicals) => bulkInsertHistoricals(historicals), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addHistoricalSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['historical-data']);
    },
  });
};

export const useUpdateHistorical = (editHistoricalSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((historical) => updateHistoricals(historical), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      editHistoricalSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['historical-data']);
    },
  });
};

export const useDeleteHistorical = (deleteHistoricalSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteHistoricals(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      deleteHistoricalSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['historical-data']);
    },
  });
};

export const useHistoricalCount = () => {
  return useQuery(['historical-count'], () => countHistoricals('true'), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useResolvedAdShareCount = () => {
  return useQuery(
    ['resolved-adshare-count'],
    () =>
      countHistoricalConditional({
        stringify: 'true',
        dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in favor of AdShare',
        atrributes: 'main_data_composite'
      }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      onSuccess: () => { },
      onError: (error) => {
        if (Array.isArray(error?.data?.error)) {
          error?.data?.error?.forEach((el) =>
            toast.error(el?.message, {
              position: 'top-right',
            })
          );
        } else {
          toast.error(error?.data?.message, {
            position: 'top-right',
          });
        }
      },
    }
  );
};

export const useResolvedThirdPartyCount = () => {
  return useQuery(
    ['resolved-thirdparty-count'],
    () =>
      countHistoricalConditional(
        {
          stringify: 'true',
          dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in favor of Third Party',
          atrributes: 'main_data_composite'
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      onSuccess: () => { },
      onError: (error) => {
        if (Array.isArray(error?.data?.error)) {
          error?.data?.error?.forEach((el) =>
            toast.error(el?.message, {
              position: 'top-right',
            })
          );
        } else {
          toast.error(error?.data?.message, {
            position: 'top-right',
          });
        }
      },
    }
  );
};

export const useResolvedCount = () => {
  return useQuery(
    ['resolved-count'],
    () =>
      countHistoricalConditional(
        {
          stringify: 'true',
          dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in',
          atrributes: 'main_data_composite'
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      onSuccess: () => { },
      onError: (error) => {
        if (Array.isArray(error?.data?.error)) {
          error?.data?.error?.forEach((el) =>
            toast.error(el?.message, {
              position: 'top-right',
            })
          );
        } else {
          toast.error(error?.data?.message, {
            position: 'top-right',
          });
        }
      },
    }
  );
};

export const useLast12MonthPeriod = () => {
  return useQuery(['ltm-period'], getAllPTMPeriod, {
    initialData: ['loading 1', 'loading 2'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useLast12MonthConflicts = () => {
  return useQuery(['ltm-conflicts'], getAllPTMConflicts, {
    initialData: ['0', '0'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useLast12MonthResolved = () => {
  return useQuery(['ltm-resolved'], getAllPTMResolved, {
    initialData: ['0', '0'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useLast12MonthBackInConflict = () => {
  return useQuery(['ltm-conflictback'], getAllPTMReturnedConflicts, {
    initialData: ['0', '0'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useFavorConflicts = () => {
  return useQuery(['fvr-conflicts'], getFavorConflicts, {
    initialData: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useFavorThirdParty = () => {
  return useQuery(['fvr-thirdparty'], getFavorThirdParty, {
    initialData: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useFavorAdShare = () => {
  return useQuery(['fvr-adshare'], getFavorAdShare, {
    initialData: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useFavorOther = () => {
  return useQuery(['fvr-other'], getFavorOther, {
    initialData: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useTopTenCPs = () => {
  return useQuery(['topten-period'], getHighestCPCount, {
    initialData: [{ label: 'loading', value: 100 }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useRadarData = (onSuccess) => {
  return useQuery(['resolve-radar'], getResolveRadar, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      const resolver = (input_array, output_array) => {
        switch (input_array.name1) {
          case 'OTR Out | Accepted':
            output_array[0] = parseInt(input_array.value, 10);
            break;
          case 'Other | Resolved':
            output_array[1] = parseInt(input_array.value, 10);
            break;
          case 'OTR Out | Resolved':
            output_array[2] = parseInt(input_array.value, 10);
            break;
          case 'OTR Out | Rejected':
            output_array[3] = parseInt(input_array.value, 10);
            break;
          default:
            output_array[4] = parseInt(input_array.value, 10);
            break;
        }
      };
      const ThirdPartyFavor = [0, 0, 0, 0, 0];
      const AdShareFavor = [0, 0, 0, 0, 0];
      const Conflicted = [0, 0, 0, 0, 0];
      const Other_Resolved = [0, 0, 0, 0, 0];

      // eslint-disable-next-line
      Object.entries(data).map(([key, value]) => {
        switch (value.label1) {
          case 'Resolved in favor of Third Party':
            resolver(value, ThirdPartyFavor);
            break;
          case 'Resolved in favor of AdShare':
            resolver(value, AdShareFavor);
            break;
          case 'In Conflicted Stage':
            resolver(value, Conflicted);
            break;
          default:
            resolver(value, Other_Resolved);
            break;
        }
        // eslint-disable-next-line
        return;
      });
      onSuccess([ThirdPartyFavor, AdShareFavor, Conflicted, Other_Resolved]);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateRadarData = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getResolveRadar(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['resolve-radar'], data);
    },
  })
};

export const useUpdateTopTenCPs = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getHighestCPCount(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['topten-period'], data);
    },
  })
};

export const useUpdateFavorOther = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getFavorOther(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['fvr-other'], data);
    },
  })
};

export const useUpdateFavorAdShare = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getFavorAdShare(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['fvr-adshare'], data);
    },
  })
};

export const useUpdateFavorThirdParty = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getFavorThirdParty(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['fvr-thirdparty'], data);
    },
  })
};

export const useUpdateFavorConflicts = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getFavorConflicts(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['fvr-conflicts'], data);
    },
  })
};

export const useUpdateLast12MonthBackInConflict = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getAllPTMReturnedConflicts(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-conflictback'], data);
    },
  })
};

export const useUpdateLast12MonthResolved = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getAllPTMResolved(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-resolved'], data);
    },
  })
};

export const useUpdateLast12MonthConflicts = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getAllPTMConflicts(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-conflicts'], data);
    },
  })
};

export const useUpdateLast12MonthPeriod = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => getAllPTMPeriod(
        {
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-period'], data);
    },
  })
};

export const useUpdateResolvedCount = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => countHistoricalConditional(
        {
          stringify: 'true',
          dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in',
          atrributes: 'main_data_composite_active',
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['resolved-count'], data);
    },
  })
};

export const useUpdateResolvedThirdPartyCount = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => countHistoricalConditional(
        {
          stringify: 'true',
          dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in favor of Third Party',
          atrributes: 'main_data_composite_active',
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['resolved-thirdparty-count'], data);
    },
  })
};

export const useUpdateResolvedAdShareCount = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { period,
      silo,
      customer,
      status,
      favor,
      actiontaken,
      actiontype,
      ownership }) => countHistoricalConditional(
        {
          stringify: 'true',
          dimension: 'historical_conflict_status:Resolved||resolved_in_favor_of:Resolved in favor of AdShare',
          atrributes: 'main_data_composite_active',
          period,
          silo,
          customer,
          status,
          favor,
          actiontaken,
          actiontype,
          ownership
        }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['resolved-adshare-count'], data);
    },
  })
};

export const useHistoricalMonths = (onSuccess) => {
  return useQuery(['form-historical-months'], getPeriods, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

