import PropTypes from 'prop-types';

// sections
import { ComboChart } from '../sections/dashboard/templates';

const HistoricalStatsChart = ({ useLast12MonthPeriod, useLast12MonthConflicts, useLast12MonthResolved, useLast12MonthBackInConflict }) => {
  const { data: period } = useLast12MonthPeriod();
  const { data: conflicts } = useLast12MonthConflicts();
  const { data: resolved } = useLast12MonthResolved();
  const { data: returnConflicts } = useLast12MonthBackInConflict();

  return (
    <ComboChart
      title="Conflicts History By Month"
      subheader=""
      chartLabels={period}
      chartData={[
        {
          name: 'Conflicts',
          type: 'line', // 'area', 'column'
          fill: 'solid',
          data: conflicts,
        },
        {
          name: 'Resolved',
          type: 'line', // 'area', 'column'
          fill: 'solid',
          data: resolved,
        },
        {
          name: 'Back In Conflict',
          type: 'line', // 'area', 'column'
          fill: 'solid',
          data: returnConflicts,
        },
      ]}
    />
  );
}

HistoricalStatsChart.propTypes = {
  useLast12MonthPeriod: PropTypes.func,
  useLast12MonthConflicts: PropTypes.func,
  useLast12MonthResolved: PropTypes.func,
  useLast12MonthBackInConflict: PropTypes.func,
};

export default HistoricalStatsChart;