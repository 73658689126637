import PropTypes from 'prop-types';

import { Button } from '@mui/material';
// import { makeStyles } from 'tss-react/mui';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     minWidth: 0,
//     margin: theme.spacing(0.5),
//   },
//   secondary: {
//     backgroundColor: theme.palette.secondary.light,
//     '& .MuiButton-label': {
//       color: theme.palette.secondary.main,
//     },
//   },
//   primary: {
//     backgroundColor: theme.palette.primary.light,
//     '& .MuiButton-label': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));

const ActionButton = (props) => {
  const {
    // color,
    children,
    onClick,
  } = props;
  // const classes = useStyles();

  return (
    <Button
      sx={{
        marginRight: 1,
        marginBottom: 1,
      }}
      // className={`${classes.root} ${classes[color]}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  // color: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default ActionButton;