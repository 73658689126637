import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectCard } from '../../sections/dashboard/templates';


const DropdownFilter = ({ title, list = '*', setList, useList }) => {

    const initialLoading = { dataset: [{ id: 'Loading..', title: 'Loading..' }] }

    const [newList, setNewList] = useState(initialLoading);

    const sort_by = (field, reverse, primer) => {

        const key = primer ?
            (x) => {
                const pmx = primer(x[field])
                return pmx
            } :
            (x) => {
                const pmx = x[field]
                return pmx
            };

        reverse = !reverse ? 1 : -1;

        return (a, b) => {
            const ae = (a = key(a), b = key(b), reverse * ((a > b) - (b > a)));
            return ae
        }
    }

    const listSuccess = (data) => {
        if (data) {
            const clist = {
                dataset: [
                    ...new Set(
                        data.map((item) => {
                            return { id: item.id, title: item.id };
                        })
                    ),
                ],
            };
            clist.dataset.sort(sort_by('id', false, (a) => a.toUpperCase()));
            clist.dataset.unshift({ id: 'Unassigned', title: 'Unassigned' });
            setNewList(clist);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        if (list !== '*') {
            try {
                if (list.dataset) { setNewList(list) } else {
                    setNewList({
                        dataset: list
                    })
                }
            } catch (e) { console.log(e) }
            // eslint-disable-next-line
            list = '*';
        }
        // eslint-disable-next-line
    }, [])

    // eslint-disable-next-line
    const { data } = useList(listSuccess);

    return (
        <>
            <SelectCard
                header={title}
                items={newList}
                value={list}
                cardshadow={0}
                bottomMargin={'-10px'}
                setValue={setList}
            />
        </>
    );
}

DropdownFilter.propTypes = {
    title: PropTypes.string,
    list: PropTypes.any,
    setList: PropTypes.func,
    useList: PropTypes.func,
};

export default DropdownFilter;