import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// components
import { useForm, Form } from '../sections/form/useForm';

import { FormInput, FormDatePicker, FormButton } from '../sections/form/controls';

const initialFValues = {
  id: 0,
  backpay_silo: '',
  backpay_period: new Date(),
  backpay_asset_id: '',
  active_asset_id: '',
  ownership: '',
  backpay_asset_type: '',
  backpay_artist: '',
  backpay_asset_title: '',
  backpay_custom_id: '',
  backpay_isrc: '',
  backpay_upc: '',
  backpay_gRid: '',
  backpay_label: '',
  administer_publish_rights: '',
  content_type: '',
  claim_type: '',
  record_type: '',
  country: '',
  day: new Date(),
  total_views: '',
  ad_enabled_views: '',
  watch_page_views: '',
  embedded_page_views: '',
  channel_page_video_views: '',
  total_earnings: '',
  gross_youtube_sold_revenue: '',
  gross_partner_sold_revenue: '',
  gross_adSense_sold_revenue: '',
  net_youtube_sold_revenue: '',
  net_adsense_sold_revenue: '',
};



const BackPayForm = (props) => {
  const { addBackPay, backPayNew } = props;

  const validate = (fieldValues = values) => {
    // MORE VALIDATION REQUIRED, POSTPONED FOR LATER
    /* eslint-disable-next-line prefer-const */
    let temp = { ...errors };
    if ('asset_id' in fieldValues)
      temp.asset_id = fieldValues.asset_id.length > 5 ? '' : 'Please enter a valid Asset ID.';

    if ('silo' in fieldValues) temp.silo = fieldValues.silo.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // eslint-disable-next-line
      addBackPay(values, resetForm);
    }
  };

  useEffect(() => {
    if (backPayNew !== null) {
      // eslint-disable-next-line
      setValues({
        ...backPayNew,
      });
    }
    // eslint-disable-next-line
  }, [backPayNew]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container minWidth="300" width="280">
        <Grid item sm={6}>
          <FormInput
            name="backpay_silo"
            label="Backpay Silo"
            value={values.backpay_silo}
            onChange={handleInputChange}
            error={errors.backpay_silo}
          />

          <FormInput
            name="backpay_asset_id"
            label="Backpay Asset Id"
            value={values.backpay_asset_id}
            onChange={handleInputChange}
            error={errors.backpay_asset_id}
          />

          <FormInput
            name="ownership"
            label="Ownership"
            value={values.ownership}
            onChange={handleInputChange}
            error={errors.ownership}
          />

          <FormInput
            name="backpay_artist"
            label="Backpay Artist"
            value={values.backpay_artist}
            onChange={handleInputChange}
            error={errors.backpay_artist}
          />

          <FormInput
            name="backpay_custom_id"
            label="Backpay Custom Id"
            value={values.backpay_custom_id}
            onChange={handleInputChange}
            error={errors.backpay_custom_id}
          />

          <FormInput
            name="backpay_upc"
            label="Backpay Upc"
            value={values.backpay_upc}
            onChange={handleInputChange}
            error={errors.backpay_upc}
          />

          <FormInput
            name="backpay_label"
            label="Backpay Label"
            value={values.backpay_label}
            onChange={handleInputChange}
            error={errors.backpay_label}
          />

          <FormInput
            name="content_type"
            label="Content Type"
            value={values.content_type}
            onChange={handleInputChange}
            error={errors.content_type}
          />

          <FormInput
            name="record_type"
            label="Record Type"
            value={values.record_type}
            onChange={handleInputChange}
            error={errors.record_type}
          />

          <FormDatePicker name="day" label="Day" value={values.day} onChange={handleInputChange} />

          <FormInput
            name="ad_enabled_views"
            label="Ad Enabled Views"
            value={values.ad_enabled_views}
            onChange={handleInputChange}
            error={errors.ad_enabled_views}
          />

          <FormInput
            name="embedded_page_views"
            label="Embedded Page Views"
            value={values.embedded_page_views}
            onChange={handleInputChange}
            error={errors.embedded_page_views}
          />

          <FormInput
            name="total_earnings"
            label="Total Earnings"
            value={values.total_earnings}
            onChange={handleInputChange}
            error={errors.total_earnings}
          />

          <FormInput
            name="gross_partner_sold_revenue"
            label="Gross Partner Sold Revenue"
            value={values.gross_partner_sold_revenue}
            onChange={handleInputChange}
            error={errors.gross_partner_sold_revenue}
          />

          <FormInput
            name="net_youtube_sold_revenue"
            label="Net Youtube Sold Revenue"
            value={values.net_youtube_sold_revenue}
            onChange={handleInputChange}
            error={errors.net_youtube_sold_revenue}
          />
        </Grid>
        <Grid item sm={6}>
          <FormDatePicker
            name="backpay_period"
            label="Backpay Period"
            value={values.backpay_period}
            onChange={handleInputChange}
          />

          <FormInput
            name="active_asset_id"
            label="Active Asset Id"
            value={values.active_asset_id}
            onChange={handleInputChange}
            error={errors.active_asset_id}
          />

          <FormInput
            name="backpay_asset_type"
            label="Backpay Asset Type"
            value={values.backpay_asset_type}
            onChange={handleInputChange}
            error={errors.backpay_asset_type}
          />

          <FormInput
            name="backpay_asset_title"
            label="Backpay Asset Title"
            value={values.backpay_asset_title}
            onChange={handleInputChange}
            error={errors.backpay_asset_title}
          />

          <FormInput
            name="backpay_isrc"
            label="Backpay Isrc"
            value={values.backpay_isrc}
            onChange={handleInputChange}
            error={errors.backpay_isrc}
          />

          <FormInput
            name="backpay_gRid"
            label="Backpay Grid"
            value={values.backpay_gRid}
            onChange={handleInputChange}
            error={errors.backpay_gRid}
          />

          <FormInput
            name="administer_publish_rights"
            label="Administer Publish Rights"
            value={values.administer_publish_rights}
            onChange={handleInputChange}
            error={errors.administer_publish_rights}
          />

          <FormInput
            name="claim_type"
            label="Claim Type"
            value={values.claim_type}
            onChange={handleInputChange}
            error={errors.claim_type}
          />

          <FormInput
            name="country"
            label="Country"
            value={values.country}
            onChange={handleInputChange}
            error={errors.country}
          />

          <FormInput
            name="total_views"
            label="Total Views"
            value={values.total_views}
            onChange={handleInputChange}
            error={errors.total_views}
          />

          <FormInput
            name="watch_page_views"
            label="Watch Page Views"
            value={values.watch_page_views}
            onChange={handleInputChange}
            error={errors.watch_page_views}
          />

          <FormInput
            name="channel_page_video_views"
            label="Channel Page Video Views"
            value={values.channel_page_video_views}
            onChange={handleInputChange}
            error={errors.channel_page_video_views}
          />

          <FormInput
            name="gross_youtube_sold_revenue"
            label="Gross Youtube Sold Revenue"
            value={values.gross_youtube_sold_revenue}
            onChange={handleInputChange}
            error={errors.gross_youtube_sold_revenue}
          />

          <FormInput
            name="gross_adSense_sold_revenue"
            label="Gross Adsense Sold Revenue"
            value={values.gross_adSense_sold_revenue}
            onChange={handleInputChange}
            error={errors.gross_adSense_sold_revenue}
          />

          <FormInput
            name="net_adsense_sold_revenue"
            label="Net Adsense Sold Revenue"
            value={values.net_adsense_sold_revenue}
            onChange={handleInputChange}
            error={errors.net_adsense_sold_revenue}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <FormButton
              type="submit"
              text="Insert New Record"
              sx={{
                marginTop: 2,
                marginLeft: -12,
                marginRight: 4,
              }}
            />
          </Grid>
          <Grid item>
            <FormButton
              text="Start Over"
              color="grey"
              onClick={resetForm}
              sx={{
                marginTop: 2,
                marginLeft: 8,
                marginRight: 5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

BackPayForm.propTypes = {
  addBackPay: PropTypes.func,
  backPayNew: PropTypes.object,
};

export default BackPayForm;