import { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

/* eslint-disable-next-line default-param-last */
export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

useForm.propTypes = {
  initialFValues: PropTypes.object,
  validateOnChange: PropTypes.bool,
  validate: PropTypes.func,
};

const FormContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(1),
  padding: theme.spacing(3),
}));

export function Form(props) {
  const { children, ...other } = props;
  return (
    <FormContentStyle>
      <form autoComplete="off" {...other}>
        {children}
      </form>
    </FormContentStyle>
  );
}

Form.propTypes = {
  children: PropTypes.any,
};
