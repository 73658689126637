import assetDetailsAPI from '../config/axios-common';

const APItype = '/assetDetail';

export const insertAssetDetails = async (d) => {
  try {
    delete d.id;
  } catch (e) {
    console.log(e);
  }
  const response = await assetDetailsAPI({ url: `${APItype}/create`, method: 'post', data: d });
  return response;
};

export const bulkInsertAssetDetails = async (bulk) => {
  const updatequeries = `?userId=${bulk.uname}`;
  if (bulk.clean) {
    const response = await assetDetailsAPI({
      url: `${APItype}/createBulkFresh${updatequeries}`,
      method: 'post',
      data: bulk.AssetDetails,
    });
    return response;
  }
  const response = await assetDetailsAPI({
    url: `${APItype}/createBulk${updatequeries}`,
    method: 'post',
    data: bulk.AssetDetails,
  });
  return response;
};

export const updateAssetDetails = async (d) => {
  const updatequeries = `?id=${d.id}`;
  const response = await assetDetailsAPI({ url: `${APItype}/updateByID${updatequeries}`, method: 'patch', data: d });
  return response;
};

export const deleteAssetDetails = async (id) => {
  const response = await assetDetailsAPI({ url: `${APItype}/deleteByID?id=${id}`, method: 'delete' });
  return response;
};

export const getAssetDetails = async ({ grade, pageNumber, pageLimit, atrributes = null, stringify = 'false' }) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes !== null) countqueries = `${countqueries}&atrributes=${atrributes}`;
  if (pageLimit) countqueries = `${countqueries}&limit=${pageLimit}`;
  if (pageNumber) countqueries = `${countqueries}&offset=${pageNumber}`;
  if (grade) countqueries = `${countqueries}&grade=${grade}`;
  const response = await assetDetailsAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  // return response?.data.count; //.rows;
  return response?.data;
};

export const getAssetDetailsByID = async (id) => {
  const response = await assetDetailsAPI({ url: `${APItype}/findOne?id=${id}`, method: 'get' });
  return response?.data;
};

// export const countAssetDetails = async ({ stringify = 'false', atrributes = null, silo = null, customer = null }) => {
//   let updatequeries = `?stringify=${stringify}&silo=${silo}&cusname=${customer}`;
//   if (atrributes != null) {
//     updatequeries = `${updatequeries}&atrributes=${atrributes}`;
//   }
//   const response = await assetDetailsAPI({ url: `${APItype}/countAll${updatequeries}`, method: 'get' });
//   return response?.data;
// };

export const countAssetDetailConditional = async ({ stringify = 'false', dimension = null, atrributes = null, silo = null, customer = null }) => {
  let updatequeries = `?stringify=${stringify}&silo=${silo}&cusname=${customer}`;
  if (dimension != null) {
    updatequeries = `${updatequeries}&dimension=${dimension}`;
  }
  if (atrributes != null) {
    updatequeries = `${updatequeries}&atrributes=${atrributes}`;
  }
  const response = await assetDetailsAPI({ url: `${APItype}/countAll${updatequeries}`, method: 'get' });
  return response?.data;
};

export default assetDetailsAPI;
