import PropTypes from 'prop-types';

import { ScoreCard } from '../sections/dashboard/templates';


const ResolvedAssetsScorecard = ({ useResolvedCount }) => {
  const { data: resolvedCount } = useResolvedCount();

  return (
    <ScoreCard
      title="Resolved Conflicts"
      total={Number(resolvedCount)}
      color="warning"
      icon={'ant-design:android-filled'}
    />
  );
}

ResolvedAssetsScorecard.propTypes = {
  useResolvedCount: PropTypes.func,
};

export default ResolvedAssetsScorecard;