import PropTypes from 'prop-types';

import {
  Card,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from '@mui/material';

// export default function Select(props) {
//   const { name, label, value, error = null, onChange, options } = props;
const SelectCard = ({
  header = '',
  items = [{ id: 1, title: 'All' }],
  value = 'All',
  cardshadow = 2,
  bottomMargin = '0px',
  paddingTop = '25px',
  paddingLeft = '10px',
  margin = '15px',
  width = '90%',
  flexGrow = 1,
  alignItems = 'center',
  height = 'auto',
  setValue,
  ...other
}) => {
  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Card variant="soft" sx={{ boxShadow: cardshadow, marginY: bottomMargin, flexGrow: parseInt(flexGrow, 10), alignItems: alignItems.toString() }} {...other}>
      <FormControl
        sx={{
          height: height.toString(),
          width: width.toString(),
          padding: '1px',
          paddingTop: paddingTop.toString(),
          paddingLeft: paddingLeft.toString(),
          margin: margin.toString(),
          borderRadius: 0,
          flexGrow: 1
        }}
        variant="outlined"
      >
        <InputLabel
          sx={{
            padding: 1,
          }}

        >{header} </InputLabel>
        <MuiSelect color='primary' name={header} value={value} onChange={handleValueChange}>
          <MenuItem id={'All'} value={'All'}>All</MenuItem>
          {items.dataset.map((item) => (
            <MenuItem key={item.id} value={item.title}>
              {item.title}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Card>
  );
}

SelectCard.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  items: PropTypes.object,
  value: PropTypes.any,
  setValue: PropTypes.func,
  cardshadow: PropTypes.number,
  bottomMargin: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingLeft: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  flexGrow: PropTypes.number,
  alignItems: PropTypes.string,
  height: PropTypes.string,
  update: PropTypes.func,
};

export default SelectCard;