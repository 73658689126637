// material
import { Stack, Button, Typography } from '@mui/material'; // Divider

// component
import Iconify from '../../../components/common/Iconify';

// ----------------------------------------------------------------------

const AuthGoogle = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  const googleAuth = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/auth/google`, '_self'); //
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={googleAuth}>
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
          <Typography variant="body2" sx={{ paddingLeft: [1], color: 'text.secondary' }}>
            Use Office Account
          </Typography>
        </Button>
      </Stack>
    </>
  );
};

export default AuthGoogle;
