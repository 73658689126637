import PropTypes from 'prop-types';

// @mui
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;

  return (
    <div>
      <Dialog open={confirmDialog.isOpen} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
        <DialogTitle id="alert-dialog-title">{confirmDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{confirmDialog.subTitle}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={confirmDialog.onReject}>
            No
          </Button>
          <Button color="error" onClick={confirmDialog.onConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func
};

export default ConfirmDialog;