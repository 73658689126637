import { useState, useMemo } from 'react';

// @mui
import { Grid, Container, Typography } from '@mui/material';

import { useCustomerNames } from '../../services/customerService';
import { useCurrentConflicts, useUpdateCurrentConflicts } from '../../services/assetdetailService';
import { useNullService } from '../../services/nullService';
import {
  useTopTenCPs,
  useUpdateTopTenCPs,
  useFavorConflicts,
  useUpdateFavorConflicts,
  useFavorThirdParty,
  useUpdateFavorThirdParty,
  useFavorAdShare,
  useUpdateFavorAdShare,
  useFavorOther,
  useUpdateFavorOther,
  useRadarData,
  useUpdateRadarData,
  useLast12MonthPeriod,
  useUpdateLast12MonthPeriod,
  useLast12MonthConflicts,
  useUpdateLast12MonthConflicts,
  useLast12MonthResolved,
  useUpdateLast12MonthResolved,
  useLast12MonthBackInConflict,
  useUpdateLast12MonthBackInConflict,
  useResolvedAdShareCount,
  useUpdateResolvedAdShareCount,
  useResolvedCount,
  useUpdateResolvedCount,
  useResolvedThirdPartyCount,
  useUpdateResolvedThirdPartyCount,
  useHistoricalMonths
} from '../../services/historicalService';

// components
import Notification from '../../components/sections/form/Notification';
import DropdownFilter from '../../components/dashboard/filters/dropdownFilter';
import ResolvedAssetsScorecard from '../../components/dashboard/resolvedAssetsScorecard';
import ConflictedAssetsScorecard from '../../components/dashboard/conflictedAssetsScorecard';
import ResolvedAssetsAdShareScorecard from '../../components/dashboard/resolvedAssetsAdShareScorecard';
import ResolvedAssetsThirdPartyScorecard from '../../components/dashboard/resolvedAssetsThirdPartyScorecard';
import HistoricalStatsChart from '../../components/dashboard/historicalStatsChart';
import HistoricalPieChart from '../../components/dashboard/historicalPieChart';
import HistoricalCounterPartyBar from '../../components/dashboard/historicalCounterPartyBar';
import HistoricalRadarChart from '../../components/dashboard/historicalResolveRadar';
import Page from '../../components/common/Page';

import { useGlobalState } from '../../context/global/globalContext';

// import axiosInstance from '../../config/axios-common';

const Dashboard = () => {
  const {
    state: { currentUser },
  } = useGlobalState();

  const siloItems = {
    dataset: [
      { id: 'AdShare MG', title: 'AdShare MG' },
      { id: 'AdShare Third Party', title: 'AdShare Third Party' },
    ]
  };

  const cstatusItems = {
    dataset: [
      { id: 'Still in Conflict', title: 'Still in Conflict' },
      { id: 'Resolved', title: 'Resolved' },
    ]
  };

  const resolved_in_favor_ofItems = {
    dataset: [
      { id: 'In Conflicted Stage', title: 'In Conflicted Stage' },
      { id: 'Resolved in favor of AdShare', title: 'Resolved in favor of AdShare' },
      { id: 'Resolved in favor of Third Party', title: 'Resolved in favor of Third Party' },
      { id: 'Status Pending', title: 'Status Pending' },
      { id: 'Dummy Resolved', title: 'Dummy Resolved' },
    ]
  };

  // { dataset: [{ id: 'Loading..', title: 'Loading..' }] }

  const historical_ownershipItems = {
    dataset: [
      { id: 'Ownership Exists', title: 'Ownership Exists' },
      { id: 'Ownership Removed', title: 'Ownership Removed' },
    ]
  };

  const action_takenItems = {
    dataset: [
      { id: 'Pending', title: 'Pending' },
      { id: 'Accepted', title: 'Accepted' },
      { id: 'Rejected', title: 'Rejected' },
      { id: 'Resolved', title: 'Resolved' },
      { id: 'Other', title: 'Other' },
    ]
  };

  const action_typeItems = {
    dataset: [
      { id: 'OTR In', title: 'OTR In' },
      { id: 'OTR Out', title: 'OTR Out' },
      { id: 'Email In', title: 'Email In' },
      { id: 'Email Out', title: 'Email Out' },
      { id: 'Other', title: 'Other' },
    ]
  };

  const [customer, setCustomer] = useState('*');
  const [period, setPeriod] = useState('*');
  const [status, setStatus] = useState(cstatusItems);
  const [favor, setFavor] = useState(resolved_in_favor_ofItems);
  const [silo] = useState(siloItems); // setSilo not used
  const [actiontaken] = useState(action_takenItems); // setActiontaken not used
  const [actiontype] = useState(action_typeItems); // setActiontype not used
  const [ownership] = useState(historical_ownershipItems); // setOwnership not used

  const { mutate: updateCurrentConflictsData } = useUpdateCurrentConflicts();
  const { mutate: updateRadarData } = useUpdateRadarData();
  const { mutate: updateTopTenCPsData } = useUpdateTopTenCPs();
  const { mutate: updateFavorConflictsData } = useUpdateFavorConflicts();
  const { mutate: updateFavorThirdPartyData } = useUpdateFavorThirdParty();
  const { mutate: updateFavorAdShareData } = useUpdateFavorAdShare();
  const { mutate: updateFavorOtherData } = useUpdateFavorOther();
  const { mutate: updateLast12MonthPeriodData } = useUpdateLast12MonthPeriod();
  const { mutate: updateLast12MonthConflictsData } = useUpdateLast12MonthConflicts();
  const { mutate: updateLast12MonthResolvedData } = useUpdateLast12MonthResolved();
  const { mutate: updateLast12MonthBackInConflictData } = useUpdateLast12MonthBackInConflict();
  const { mutate: updateResolvedAdShareCountData } = useUpdateResolvedAdShareCount();
  const { mutate: updateResolvedCountData } = useUpdateResolvedCount();
  const { mutate: updateResolvedThirdPartyCountData } = useUpdateResolvedThirdPartyCount();

  const onUpdate = async () => {
    updateCurrentConflictsData({ silo, customer });
    updateRadarData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateTopTenCPsData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateFavorConflictsData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateFavorThirdPartyData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateFavorAdShareData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateFavorOtherData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateLast12MonthPeriodData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateLast12MonthConflictsData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateLast12MonthResolvedData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateLast12MonthBackInConflictData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateResolvedAdShareCountData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateResolvedCountData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
    updateResolvedThirdPartyCountData({ period, silo, customer, status, favor, actiontaken, actiontype, ownership });
  };

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

  useMemo(() => {
    // eslint-disable-next-line
    onUpdate();
    setNotify({
      isOpen: true,
      message: `Filtering Multiple Charts.`,
      type: 'warning',
    });
    // eslint-disable-next-line
  }, [period, silo, customer, status, favor, actiontaken, actiontype, ownership]);

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth={false}
        // maxWidth="xl" 
        >
          <Typography variant="body2" sx={{ mb: 0 }}>
            Welcome {currentUser?.username}
          </Typography>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Below is a quick overview of the state of all conflicts at present within Rights Management. For details on BackPay, please switch to the Customer Dashboard.
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Customer" list={customer} setList={setCustomer} useList={useCustomerNames} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Period" list={period} setList={setPeriod} useList={useHistoricalMonths} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Status" list={status} setList={setStatus} useList={useNullService} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Resolved Favor" list={favor} setList={setFavor} useList={useNullService} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Silo" list={silo} setList={setSilo} useList={useNullService} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Action Taken" list={actiontaken} setList={setActiontaken} useList={useNullService} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Action Type" list={actiontype} setList={setActiontype} useList={useNullService} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DropdownFilter title="Ownership" list={ownership} setList={setOwnership} useList={useNullService} />
            </Grid> */}

            <Grid item xs={12} sm={6} md={3}>
              <ConflictedAssetsScorecard
                useCurrentConflicts={useCurrentConflicts}
                favor={favor}
                status={status}
                actiontaken={actiontaken}
                actiontype={actiontype}
                ownership={ownership}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <ResolvedAssetsScorecard useResolvedCount={useResolvedCount} favor={favor} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <ResolvedAssetsAdShareScorecard useResolvedAdShareCount={useResolvedAdShareCount} favor={favor} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <ResolvedAssetsThirdPartyScorecard useResolvedThirdPartyCount={useResolvedThirdPartyCount} favor={favor} />
            </Grid>

            <Grid item xs={12} md={6} lg={9}>
              <HistoricalStatsChart useLast12MonthPeriod={useLast12MonthPeriod} useLast12MonthConflicts={useLast12MonthConflicts} useLast12MonthResolved={useLast12MonthResolved} useLast12MonthBackInConflict={useLast12MonthBackInConflict} />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <HistoricalPieChart
                useFavorConflicts={useFavorConflicts}
                useFavorThirdParty={useFavorThirdParty}
                useFavorAdShare={useFavorAdShare}
                useFavorOther={useFavorOther}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={9}>
              <HistoricalCounterPartyBar useTopTenCPs={useTopTenCPs} />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <HistoricalRadarChart useRadarData={useRadarData} />
            </Grid>
          </Grid>
        </Container>
      </Page>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default Dashboard;