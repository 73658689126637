// components
import Page from '../../components/common/Page';

const ConflictsDataStudio = () => {
  return (
    <Page title="DataStudio">
      <div>DataStudio</div>
    </Page>
  );
}

export default ConflictsDataStudio;