import { useEffect } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// components
import { useForm, Form } from '../sections/form/useForm';

import { FormInput, FormButton } from '../sections/form/controls';

const initialFValues = {
  id: 0,
  customer_id: '',
  customer: '',
  customer_code_6_digit: '',
  customer_code: '',
  customer_normalized: '',
};

const CustomerForm = (props) => {
  const { addCustomer, customerNew } = props;

  const validate = (fieldValues = values) => {
    // MORE VALIDATION REQUIRED, POSTPONED FOR LATER
    /* eslint-disable-next-line prefer-const */
    let temp = { ...errors };
    if ('asset_id' in fieldValues)
      temp.asset_id = fieldValues.asset_id.length > 5 ? '' : 'Please enter a valid Asset ID.';
    if ('silo' in fieldValues) temp.silo = fieldValues.silo.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // eslint-disable-next-line
      addCustomer(values, resetForm);
    }
  };

  useEffect(() => {
    if (customerNew !== null) {
      // eslint-disable-next-line
      setValues({
        ...customerNew,
      });
    }
    // eslint-disable-next-line
  }, [customerNew]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container minWidth="300" width="280">
        <Grid item sm={6}>
          <FormInput
            name="customer_id"
            label="Customer ID"
            value={values.customer_id}
            onChange={handleInputChange}
            error={errors.customer_id}
          />

          <FormInput
            name="customer_code_6_digit"
            label="Customer Code 6 Digit"
            value={values.customer_code_6_digit}
            onChange={handleInputChange}
            error={errors.customer_code_6_digit}
          />

          <FormInput
            name="customer_normalized"
            label="Customer Normalized"
            value={values.customer_normalized}
            onChange={handleInputChange}
            error={errors.customer_normalized}
          />
        </Grid>
        <Grid item sm={6}>
          <FormInput
            name="customer"
            label="Customer"
            value={values.customer}
            onChange={handleInputChange}
            error={errors.customer}
          />

          <FormInput
            name="customer_code"
            label="Customer Code"
            value={values.customer_code}
            onChange={handleInputChange}
            error={errors.customer_code}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <FormButton
              type="submit"
              text="Insert New Record"
              sx={{
                marginTop: 2,
                marginLeft: -12,
                marginRight: 4,
              }}
            />
          </Grid>
          <Grid item>
            <FormButton
              text="Start Over"
              color="grey"
              onClick={resetForm}
              sx={{
                marginTop: 2,
                marginLeft: 8,
                marginRight: 5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

CustomerForm.propTypes = {
  addCustomer: PropTypes.func,
  customerNew: PropTypes.object,
};

export default CustomerForm;