import customerAPI from '../config/axios-common';

const APItype = '/customer';

export const insertCustomers = async (d) => {
  try {
    delete d.id;
  } catch (e) {
    console.log(e);
  }
  const response = await customerAPI({ url: `${APItype}/create`, method: 'post', data: d });
  return response;
};

export const bulkInsertCustomers = async (bulk) => {
  const updatequeries = `?userId=${bulk.uname}`;
  if (bulk.clean) {
    const response = await customerAPI({
      url: `${APItype}/createBulkFresh${updatequeries}`,
      method: 'post',
      data: bulk.Customers,
    });
    return response;
  }
  const response = await customerAPI({
    url: `${APItype}/createBulk${updatequeries}`,
    method: 'post',
    data: bulk.Customers,
  });
  return response;
};

export const updateCustomers = async (d) => {
  const updatequeries = `?id=${d.id}`;
  const response = await customerAPI({ url: `${APItype}/updateByID${updatequeries}`, method: 'patch', data: d });
  return response;
};

export const deleteCustomers = async (id) => {
  const response = await customerAPI({ url: `${APItype}/deleteByID?id=${id}`, method: 'delete' });
  return response;
};

export const getCustomers = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await customerAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getCustomersByID = async (id) => {
  const response = await customerAPI({ url: `${APItype}/findOne?id=${id}`, method: 'get' });
  return response?.data;
};

export const countCustomers = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await customerAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const countCustomerConditional = async (stringify = 'false', dimension = null, atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (dimension != null) {
    countqueries = `${countqueries}&dimension=${dimension}`;
  }
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }

  const response = await customerAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getCustomNames = async (stringify = 'false') => {
  const countqueries = `?stringify=${stringify}`;
  const response = await customerAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  // return response?.data;
  const rp = response?.data;
  const arrayColumn = (arr, n) =>
    arr.map((x) => {
      return { id: x[n] };
    });
  const finalreturn = arrayColumn(rp, 'customer');
  const uniques = [...new Map(finalreturn.map((item) => [item.id, item])).values()];
  return uniques;
};

export default customerAPI;
