import PropTypes from 'prop-types';

// import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles(() => ({ // theme
  root: {
    marginBottom: 20,
    marginLeft: 20,
    padding: 5,
  },
  label: {
    textTransform: 'none',
  },
}));

// const ButtonContentStyle = styled('div')(({ theme }) => ({
//   root: {
//     margin: theme.spacing(0.5),
//     padding: theme.spacing(3),
//   },
//   label: {
//     textTransform: 'none',
//   },
// }));

const Button = (props) => {
  const { text, size, color, variant, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      sx={{
        marginRight: 1,
        marginBottom: 1,
      }}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;