// component
import BalanceIcon from '@mui/icons-material/Balance';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ArticleIcon from '@mui/icons-material/Article';
// import AlbumIcon from '@mui/icons-material/Album';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import Iconify from '../../components/common/Iconify';
// const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const menu = [
  {
    icon: <BalanceIcon />,
    title: 'Rights Management',
    pad: 0,
    items: [
      {
        icon: <YouTubeIcon />,
        title: 'YouTube',
        pad: 1,
        items: [
          {
            icon: <BarChartIcon />,
            title: 'Dashboards',
            pad: 2,
            items: [
              {
                icon: <AutoGraphIcon />,
                title: 'Summary',
                pad: 3,
                path: '/main/dashboard',
              },
              {
                icon: <AutoGraphIcon />,
                title: 'Customer Backpay',
                pad: 3,
                path: '/main/backpaydashboard',
              },
              // {
              //   icon: getIcon('eva:google-fill'),
              //   title: 'Data Studio',
              //   pad: 3,
              //   path: 'main/datastudio',
              // },
            ],
          },
          {
            icon: <Inventory2Icon />,
            title: 'Workspace',
            pad: 2,
            items: [
              {
                title: 'Current Conflicts',
                path: '/main/assetdetails',
                pad: 3,
                icon: <ArticleIcon />,
              },
              {
                title: 'Historical Conflicts',
                path: '/main/historical',
                pad: 3,
                icon: <ArticleIcon />,
              },
              {
                title: 'BackPay Revenue',
                path: '/main/backpay',
                pad: 3,
                icon: <ArticleIcon />,
              },
              {
                title: 'RPM Management',
                path: '/main/rpmstats',
                pad: 3,
                icon: <ArticleIcon />,
              },
              {
                title: 'Customer Records',
                path: '/main/customers',
                pad: 3,
                icon: <ArticleIcon />,
              },
              // {
              //   title: 'Offboarded Records',
              //   path: '/main/offboardedcustomers',
              //   pad: 3,
              //   icon: <ArticleIcon />,
              // },
            ],
          },
        ],
      },
      // {
      //   icon: <AlbumIcon />,
      //   title: 'SoundExchange',
      //   pad: 1,
      //   items: [],
      // },
      // {
      //   icon: <FacebookIcon />,
      //   title: 'Facebook',
      //   pad: 1,
      //   items: [],
      // },
    ],
  },
];
