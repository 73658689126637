import PropTypes from 'prop-types';

import { ScoreCard } from '../sections/dashboard/templates';


const ResolvedAssetsThirdPartyScorecard = ({ useResolvedThirdPartyCount, favor }) => {
  const { data: resolvedThirdParty } = useResolvedThirdPartyCount();
  return (
    <ScoreCard
      title="Third Party Resolved"
      total={favor instanceof Object || favor === 'All' || favor === '*' || favor === 'Resolved in favor of Third Party' ? Number(resolvedThirdParty) : 0}
      color="scorecardpurple"
      backcolor="b" // "info"
      textcolor="a"
      icon={'ant-design:android-filled'}
    />
  );
}

ResolvedAssetsThirdPartyScorecard.propTypes = {
  favor: PropTypes.object,
  useResolvedThirdPartyCount: PropTypes.func,
};

export default ResolvedAssetsThirdPartyScorecard;