

import { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import globalReducer from './globalReducer';

const globalInitState = {
  currentUser: null,
  alert: { open: false, severity: 'info', message: '' },
};

const GlobalContext = createContext(globalInitState);

export const useGlobalState = () => {
  return useContext(GlobalContext);
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, globalInitState);

  useEffect(() => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        dispatch({ type: 'UPDATE_USER', payload: currentUser });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.any,
};

export default GlobalContextProvider;
