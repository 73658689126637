import { useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { useTheme } from '@mui/material/styles';

// sections
import { RadarChart } from '../sections/dashboard/templates';

const HistoricalRadarChart = ({ useRadarData }) => {
  const [otrThirdParty, setOTRThirdParty] = useState([0, 0, 0, 0, 0]);
  const [otrAdShare, setOTRAdShare] = useState([0, 0, 0, 0, 0]);
  const [otherResolved, setOtherResolved] = useState([0, 0, 0, 0, 0]);

  const onSuccess = (data) => {
    setOTRThirdParty(data[0]);
    setOTRAdShare(data[1]);
    setOtherResolved(data[3]);
  };

  // eslint-disable-next-line
  const { data: resolver } = useRadarData(onSuccess);

  const theme = useTheme();

  return (
    <RadarChart
      title="Resolution Status by Action Taken"
      chartLabels={['OTR Out : Accepted', 'Other', 'OTR Out : Resolved', 'OTR Out : Rejected', 'OTR Out : Pending']}
      chartData={[
        { name: 'In favor of AdShare', data: otrAdShare },
        { name: 'Other', data: otherResolved },
        { name: 'In favor of Third Party', data: otrThirdParty },
      ]}
      chartColors={[...Array(5)].map(() => theme.palette.text.secondary)}
    />
  );
}

HistoricalRadarChart.propTypes = {
  useRadarData: PropTypes.func,
};

export default HistoricalRadarChart;