import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE,OPTIONS',
    // 'Access-Control-Max-Age': 1728000, // set if required
    'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Authorization', // * for everything
    'Access-Control-Expose-Headers':
      'Access-Control-Allow-Methods, Access-Control-Allow-Headers, Access-Control-Allow-Origin, Authorization',
    'Access-Control-Allow-Origin': process.env.REACT_APP_CLIENT_URL,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
  },
  timeout: 120000,
  withCredentials: true,
});

export default axiosInstance;
