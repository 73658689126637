import { useMemo } from 'react';
import PropTypes from 'prop-types';

// sections
import { ComboChart } from '../sections/dashboard/templates';

import {
  useLast12MonthPeriod,
  useLast12MonthAll,
  useUpdateLast12MonthAll,
  useUpdateTotalRevenue,
  useUpdateTotalUSViews,
  useUpdateTotalView,
  useUpdateGrossSold,
} from '../../services/backpayService';

const HistoricalStatsChart = ({ period = '*', customer = '*' }) => {
  const { data: timeline } = useLast12MonthPeriod();
  const { data: allbackpays } = useLast12MonthAll();

  const { mutate: updateLast12MonthAll } = useUpdateLast12MonthAll();

  const { mutate: updateTotalRevenue } = useUpdateTotalRevenue();
  const { mutate: updateTotalUSViews } = useUpdateTotalUSViews();
  const { mutate: updateTotalView } = useUpdateTotalView();
  const { mutate: updateGrossSold } = useUpdateGrossSold();

  const onUpdate = async () => {
    updateLast12MonthAll({ period, customer });
    updateTotalRevenue({ period, customer });
    updateTotalUSViews({ period, customer });
    updateTotalView({ period, customer });
    updateGrossSold({ period, customer });
  };

  // eslint-disable-next-line
  useMemo(() => {
    // eslint-disable-next-line
    onUpdate();
    // eslint-disable-next-line
  }, [period, customer]);

  return (
    <ComboChart
      title="BackPay Revenue For Past 12 Months (By Report Period)"
      subheader=""
      chartLabels={timeline}
      height={458}
      chartData={[
        {
          name: 'Total Earnings',
          type: 'line', // 'area', 'column'
          fill: 'solid',
          data: allbackpays,
        },
      ]}
    />
  );
}

HistoricalStatsChart.propTypes = {
  period: PropTypes.any,
  customer: PropTypes.any,
};

export default HistoricalStatsChart;
