import backPayAPI from '../config/axios-common';

const APItype = '/backPay';

export const insertBackPays = async (d) => {
  try {
    delete d.id;
  } catch (e) {
    console.log(e);
  }
  const response = await backPayAPI({ url: `${APItype}/create`, method: 'post', data: d });
  return response;
};

export const bulkInsertBackPays = async (bulk) => {
  const updatequeries = `?userId=${bulk.uname}`;
  if (bulk.clean) {
    const response = await backPayAPI({
      url: `${APItype}/createBulkFresh${updatequeries}`,
      method: 'post',
      data: bulk.BackPays,
    });
    return response;
  }
  const response = await backPayAPI({
    url: `${APItype}/createBulk${updatequeries}`,
    method: 'post',
    data: bulk.BackPays,
  });
  return response;
};

export const updateBackPays = async (d) => {
  const updatequeries = `?id=${d.id}`;
  const response = await backPayAPI({ url: `${APItype}/updateByID${updatequeries}`, method: 'patch', data: d });
  return response;
};

export const deleteBackPays = async (id) => {
  const response = await backPayAPI({ url: `${APItype}/deleteByID?id=${id}`, method: 'delete' });
  return response;
};

export const getBackPays = async ({ pageNumber, pageLimit, atrributes = null, stringify = 'false' }) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes !== null) countqueries = `${countqueries}&atrributes=${atrributes}`;
  if (pageLimit) countqueries = `${countqueries}&limit=${pageLimit}`;
  if (pageNumber) countqueries = `${countqueries}&offset=${pageNumber}`;
  const response = await backPayAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getBackPaysByID = async (id) => {
  const response = await backPayAPI({ url: `${APItype}/findOne?id=${id}`, method: 'get' });
  return response?.data;
};

export const countBackPays = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await backPayAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const countBackPayConditional = async (stringify = 'false', dimension = null, atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (dimension != null) {
    countqueries = `${countqueries}&dimension=${dimension}`;
  }
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }

  const response = await backPayAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getBackPayFeed = async () => {
  const response = await backPayAPI({ url: `${APItype}/tableFeed`, method: 'get' });
  return response?.data;
};

export const getBackPayDashboardFeed = async () => {
  const response = await backPayAPI({ url: `${APItype}/dashboardFeed`, method: 'get' });
  return response?.data;
};

export const getPTMPeriod = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/chartAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'monthyear').reverse();
};

export const getPTMMusic = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;

  const response = await backPayAPI({ url: `${APItype}/chartMusic${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMMusicVideo = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/chartMusicVideo${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMArtTrack = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/chartArtTrack${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMAll = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/chartAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMNull = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/chartNull${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getTotalRevenue = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/totalRevenue${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getTotalViews = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/totalViews${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getTotalUSViews = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/totalUSViews${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getGrossSold = async ({ period, customer }) => {
  const updatequeries = `?period=${period}&cusname=${customer}`;
  const response = await backPayAPI({ url: `${APItype}/grossSold${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getPeriods = async () => {
  const response = await backPayAPI({ url: `${APItype}/allperiods`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) =>
    arr.map((x) => {
      return { id: x[n] };
    });
  const finalreturn = arrayColumn(rp, 'monthyear');
  return finalreturn;
};

export default backPayAPI;
