import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// components
import { useForm, Form } from '../sections/form/useForm';

import { FormInput, FormSelect, FormDatePicker, FormButton } from '../sections/form/controls';

const initialFValues = {
  id: 0,
  silo: '',
  rpm_grade: '',
  period: new Date(),
  conflicting_country_code: '',
  rpm_by_ownership_territory: '',
};

const siloItems = [
  { id: 'AdShare MG', title: 'AdShare MG' },
  { id: 'AdShare Third Party', title: 'AdShare Third Party' },
];

const gradeItems = [
  { id: 'Top (RPM Median 0.7 Upwards)', title: 'Top (RPM Median 0.7 Upwards)' },
  { id: 'Mid (RPM Median 0.68 to 0.5)', title: 'Mid (RPM Median 0.68 to 0.5)' },
  { id: 'Low (RPM Median 0.45 and Less)', title: 'Low (RPM Median 0.45 and Less)' },
];

const countryItems = [
  { id: 'US', title: 'US' },
  { id: 'US', title: 'US' },
  { id: 'AU', title: 'AU' },
  { id: 'NO', title: 'NO' },
  { id: 'DE', title: 'DE' },
  { id: 'HK', title: 'HK' },
  { id: 'NZ', title: 'NZ' },
  { id: 'JP', title: 'JP' },
  { id: 'CA', title: 'CA' },
  { id: 'GB', title: 'GB' },
  { id: 'SG', title: 'SG' },
  { id: 'CH', title: 'CH' },
  { id: 'SE', title: 'SE' },
  { id: 'DK', title: 'DK' },
  { id: 'KR', title: 'KR' },
  { id: 'NL', title: 'NL' },
  { id: 'FI', title: 'FI' },
  { id: 'IS', title: 'IS' },
  { id: 'TW', title: 'TW' },
  { id: 'IE', title: 'IE' },
  { id: 'AT', title: 'AT' },
  { id: 'FR', title: 'FR' },
  { id: 'BE', title: 'BE' },
  { id: 'LI', title: 'LI' },
  { id: 'PR', title: 'PR' },
  { id: 'ES', title: 'ES' },
  { id: 'LU', title: 'LU' },
  { id: 'ZA', title: 'ZA' },
  { id: 'IT', title: 'IT' },
  { id: 'SK', title: 'SK' },
  { id: 'IL', title: 'IL' },
  { id: 'MX', title: 'MX' },
  { id: 'CO', title: 'CO' },
  { id: 'CL', title: 'CL' },
  { id: 'AR', title: 'AR' },
  { id: 'PE', title: 'PE' },
  { id: 'EC', title: 'EC' },
  { id: 'MY', title: 'MY' },
  { id: 'IN', title: 'IN' },
  { id: 'BR', title: 'BR' },
  { id: 'GT', title: 'GT' },
  { id: 'TH', title: 'TH' },
  { id: 'CR', title: 'CR' },
  { id: 'TR', title: 'TR' },
  { id: 'ID', title: 'ID' },
  { id: 'DO', title: 'DO' },
  { id: 'HN', title: 'HN' },
  { id: 'PH', title: 'PH' },
  { id: 'PL', title: 'PL' },
  { id: 'RO', title: 'RO' },
  { id: 'SV', title: 'SV' },
  { id: 'PA', title: 'PA' },
  { id: 'SA', title: 'SA' },
  { id: 'UY', title: 'UY' },
  { id: 'BO', title: 'BO' },
  { id: 'HU', title: 'HU' },
  { id: 'VN', title: 'VN' },
  { id: 'CZ', title: 'CZ' },
  { id: 'AE', title: 'AE' },
  { id: 'PT', title: 'PT' },
  { id: 'VE', title: 'VE' },
  { id: 'NI', title: 'NI' },
  { id: 'PY', title: 'PY' },
  { id: 'GR', title: 'GR' },
  { id: 'UA', title: 'UA' },
  { id: 'KW', title: 'KW' },
  { id: 'BG', title: 'BG' },
  { id: 'RU', title: 'RU' },
  { id: 'EG', title: 'EG' },
  { id: 'HR', title: 'HR' },
  { id: 'RS', title: 'RS' },
  { id: 'LT', title: 'LT' },
  { id: 'SI', title: 'SI' },
  { id: 'QA', title: 'QA' },
  { id: 'LV', title: 'LV' },
  { id: 'EE', title: 'EE' },
  { id: 'OM', title: 'OM' },
  { id: 'KE', title: 'KE' },
  { id: 'NG', title: 'NG' },
  { id: 'KZ', title: 'KZ' },
  { id: 'CY', title: 'CY' },
  { id: 'MA', title: 'MA' },
  { id: 'PK', title: 'PK' },
  { id: 'BH', title: 'BH' },
  { id: 'AW', title: 'AW' },
  { id: 'GE', title: 'GE' },
  { id: 'IQ', title: 'IQ' },
  { id: 'MT', title: 'MT' },
  { id: 'BY', title: 'BY' },
  { id: 'AZ', title: 'AZ' },
  { id: 'JO', title: 'JO' },
  { id: 'GU', title: 'GU' },
  { id: 'BD', title: 'BD' },
  { id: 'GP', title: 'GP' },
  { id: 'DZ', title: 'DZ' },
  { id: 'KY', title: 'KY' },
  { id: 'TN', title: 'TN' },
  { id: 'BA', title: 'BA' },
  { id: 'MK', title: 'MK' },
  { id: 'RE', title: 'RE' },
  { id: 'UG', title: 'UG' },
  { id: 'GH', title: 'GH' },
  { id: 'GF', title: 'GF' },
  { id: 'MQ', title: 'MQ' },
  { id: 'LB', title: 'LB' },
  { id: 'TZ', title: 'TZ' },
  { id: 'VI', title: 'VI' },
  { id: 'BM', title: 'BM' },
  { id: 'PF', title: 'PF' },
  { id: 'ZW', title: 'ZW' },
  { id: 'SN', title: 'SN' },
  { id: 'LK', title: 'LK' },
  { id: 'PG', title: 'PG' },
  { id: 'KH', title: 'KH' },
  { id: 'ME', title: 'ME' },
  { id: 'TC', title: 'TC' },
  { id: 'NP', title: 'NP' },
  { id: 'AS', title: 'AS' },
  { id: 'LY', title: 'LY' },
  { id: 'MP', title: 'MP' },
  { id: 'YE', title: 'YE' },
  { id: 'YT', title: 'YT' },
  { id: 'MF', title: 'MF' },
  { id: 'JM', title: 'JM' },
  { id: 'LA', title: 'LA' },
  { id: 'VG', title: 'VG' },
  { id: 'BL', title: 'BL' },
  { id: 'CW', title: 'CW' },
  { id: 'PM', title: 'PM' },
  { id: 'FO', title: 'FO' },
  { id: 'SX', title: 'SX' },
  { id: 'GG', title: 'GG' },
  { id: 'NC', title: 'NC' },
  { id: 'WF', title: 'WF' },
  { id: 'AI', title: 'AI' },
  { id: 'JE', title: 'JE' },
  { id: 'IM', title: 'IM' },
  { id: 'EH', title: 'EH' },
  { id: 'GI', title: 'GI' },
  { id: 'NF', title: 'NF' },
  { id: 'AD', title: 'AD' },
  { id: 'AF', title: 'AF' },
  { id: 'AG', title: 'AG' },
  { id: 'AL', title: 'AL' },
  { id: 'AM', title: 'AM' },
  { id: 'AO', title: 'AO' },
  { id: 'AQ', title: 'AQ' },
  { id: 'AX', title: 'AX' },
  { id: 'BB', title: 'BB' },
  { id: 'BF', title: 'BF' },
  { id: 'BI', title: 'BI' },
  { id: 'BJ', title: 'BJ' },
  { id: 'BN', title: 'BN' },
  { id: 'BQ', title: 'BQ' },
  { id: 'BS', title: 'BS' },
  { id: 'BT', title: 'BT' },
  { id: 'BW', title: 'BW' },
  { id: 'BZ', title: 'BZ' },
  { id: 'CD', title: 'CD' },
  { id: 'CF', title: 'CF' },
  { id: 'CG', title: 'CG' },
  { id: 'CI', title: 'CI' },
  { id: 'CK', title: 'CK' },
  { id: 'CM', title: 'CM' },
  { id: 'CN', title: 'CN' },
  { id: 'CU', title: 'CU' },
  { id: 'CV', title: 'CV' },
  { id: 'CX', title: 'CX' },
  { id: 'DJ', title: 'DJ' },
  { id: 'DM', title: 'DM' },
  { id: 'ER', title: 'ER' },
  { id: 'ET', title: 'ET' },
  { id: 'FJ', title: 'FJ' },
  { id: 'FK', title: 'FK' },
  { id: 'FM', title: 'FM' },
  { id: 'GA', title: 'GA' },
  { id: 'GD', title: 'GD' },
  { id: 'GL', title: 'GL' },
  { id: 'GM', title: 'GM' },
  { id: 'GN', title: 'GN' },
  { id: 'GQ', title: 'GQ' },
  { id: 'GW', title: 'GW' },
  { id: 'GY', title: 'GY' },
  { id: 'HT', title: 'HT' },
  { id: 'IO', title: 'IO' },
  { id: 'IR', title: 'IR' },
  { id: 'KG', title: 'KG' },
  { id: 'KI', title: 'KI' },
  { id: 'KM', title: 'KM' },
  { id: 'KN', title: 'KN' },
  { id: 'KP', title: 'KP' },
  { id: 'LC', title: 'LC' },
  { id: 'LR', title: 'LR' },
  { id: 'LS', title: 'LS' },
  { id: 'MC', title: 'MC' },
  { id: 'MD', title: 'MD' },
  { id: 'MG', title: 'MG' },
  { id: 'MH', title: 'MH' },
  { id: 'ML', title: 'ML' },
  { id: 'MM', title: 'MM' },
  { id: 'MN', title: 'MN' },
  { id: 'MO', title: 'MO' },
  { id: 'MR', title: 'MR' },
  { id: 'MS', title: 'MS' },
  { id: 'MU', title: 'MU' },
  { id: 'MV', title: 'MV' },
  { id: 'MW', title: 'MW' },
  { id: 'MZ', title: 'MZ' },
  { id: 'NA', title: 'NA' },
  { id: 'NE', title: 'NE' },
  { id: 'NR', title: 'NR' },
  { id: 'NU', title: 'NU' },
  { id: 'PS', title: 'PS' },
  { id: 'PW', title: 'PW' },
  { id: 'RW', title: 'RW' },
  { id: 'SB', title: 'SB' },
  { id: 'SC', title: 'SC' },
  { id: 'SD', title: 'SD' },
  { id: 'SH', title: 'SH' },
  { id: 'SJ', title: 'SJ' },
  { id: 'SL', title: 'SL' },
  { id: 'SM', title: 'SM' },
  { id: 'SO', title: 'SO' },
  { id: 'SR', title: 'SR' },
  { id: 'SS', title: 'SS' },
  { id: 'ST', title: 'ST' },
  { id: 'SY', title: 'SY' },
  { id: 'SZ', title: 'SZ' },
  { id: 'TD', title: 'TD' },
  { id: 'TG', title: 'TG' },
  { id: 'TJ', title: 'TJ' },
  { id: 'TL', title: 'TL' },
  { id: 'TM', title: 'TM' },
  { id: 'TO', title: 'TO' },
  { id: 'TT', title: 'TT' },
  { id: 'TV', title: 'TV' },
  { id: 'UZ', title: 'UZ' },
  { id: 'VA', title: 'VA' },
  { id: 'VC', title: 'VC' },
  { id: 'VU', title: 'VU' },
  { id: 'WS', title: 'WS' },
  { id: 'XK', title: 'XK' },
  { id: 'ZM', title: 'ZM' },
  { id: 'ZZ', title: 'ZZ' },
];

const RPMForm = (props) => {
  const { addRPM, rpmNew } = props;

  const validate = (fieldValues = values) => {
    /* eslint-disable-next-line prefer-const */
    let temp = { ...errors };
    if ('asset_id' in fieldValues)
      temp.asset_id = fieldValues.asset_id.length > 5 ? '' : 'Please enter a valid Asset ID.';

    if ('silo' in fieldValues) temp.silo = fieldValues.silo.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // eslint-disable-next-line
      addRPM(values, resetForm); // ADD API
    }
  };

  useEffect(() => {
    if (rpmNew !== null) {
      // eslint-disable-next-line
      setValues({
        ...rpmNew,
      });
    }
    // eslint-disable-next-line
  }, [rpmNew]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container minWidth="300" width="280">
        <Grid item sm={6}>
          <FormSelect
            name="silo"
            label="Silo"
            value={values.silo}
            onChange={handleInputChange}
            options={siloItems}
            error={errors.silo}
          />
          <FormSelect
            name="rpm_grade"
            label="RPM Class"
            value={values.rpm_grade}
            onChange={handleInputChange}
            options={gradeItems}
            error={errors.rpm_grade}
          />
          <FormSelect
            name="conflicting_country_code"
            label="Conflicting Country Code"
            value={values.conflicting_country_code}
            onChange={handleInputChange}
            options={countryItems}
            error={errors.conflicting_country_code}
          />
        </Grid>
        <Grid item sm={6}>
          <FormDatePicker name="period" label="Period" value={values.period} onChange={handleInputChange} />
          <FormInput
            name="rpm_by_ownership_territory"
            label="RPM (USD)"
            value={values.rpm_by_ownership_territory}
            onChange={handleInputChange}
            error={errors.rpm_by_ownership_territory}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <FormButton
              type="submit"
              text="Insert New Record"
              sx={{
                marginTop: 2,
                marginLeft: -12,
                marginRight: 4,
              }}
            />
          </Grid>
          <Grid item>
            <FormButton
              text="Start Over"
              color="grey"
              onClick={resetForm}
              sx={{
                marginTop: 2,
                marginLeft: 8,
                marginRight: 5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

RPMForm.propTypes = {
  addRPM: PropTypes.func,
  rpmNew: PropTypes.object,
};

export default RPMForm;