import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getCustomers,
  insertCustomers,
  bulkInsertCustomers,
  updateCustomers,
  deleteCustomers,
  getCustomNames,
} from '../api/customerAPI';

export const useCustomer = (onSuccess) => {
  return useQuery(['customer-data'], getCustomers, {
    initialData: [{ id: 0 }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useAddCustomer = (addCustomerSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((Customer) => insertCustomers(Customer), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addCustomerSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['customer-data']);
    },
  });
};

export const useAddCustomerBulk = (addCustomerSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((Customers) => bulkInsertCustomers(Customers), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addCustomerSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['customer-data']);
    },
  });
};

export const useUpdateCustomer = (editCustomerSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((Customer) => updateCustomers(Customer), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      editCustomerSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['customer-data']);
    },
  });
};

export const useDeleteCustomer = (deleteCustomerSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteCustomers(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      deleteCustomerSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['customer-data']);
    },
  });
};

export const useCustomerNames = (onSuccess) => {
  return useQuery(['form-customers-names'], getCustomNames, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};
