import PropTypes from 'prop-types';
import { ScoreCard } from '../sections/dashboard/templates';

const switcher = (key) => {
  switch (true) {
    case key instanceof Object:
      return false;
    case key === 'All':
      return false;
    case key === '*':
      return false;
    case key === 'Still in Conflict':
      return false;
    case key === 'In Conflicted Stage':
      return false;
    case key === 'Ownership Exists':
      return false;
    default:
      return true;
  }
}

const ConflictedAssetsScorecard = ({ useCurrentConflicts, favor, status, actiontaken, actiontype, ownership }) => {
  const { data: conflictedCount } = useCurrentConflicts();
  let validator = false;
  const conditions = [favor, status, actiontaken, actiontype, ownership];

  conditions.forEach(element => { if (validator === false || validator === 'false') { validator = switcher(element) } })

  return (
    <ScoreCard
      title="Current Conflicts"
      total={validator === false || validator === 'false' ? Number(conflictedCount) : 0}
      color="error"
      icon={'ant-design:android-filled'}
    />
  );
}

ConflictedAssetsScorecard.propTypes = {
  useCurrentConflicts: PropTypes.func,
  favor: PropTypes.object,
  status: PropTypes.object,
  actiontaken: PropTypes.object,
  actiontype: PropTypes.object,
  ownership: PropTypes.object,
};

export default ConflictedAssetsScorecard;
