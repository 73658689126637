import { useMutation } from 'react-query';

const blank = () => { }

export const useNullService = () => {
    return useMutation(blank(), {
        onSuccess: () => {
            return null;
        },
        onError: () => {
            return null;
        },
        onSettled: () => {
            return null;
        },
    });
};