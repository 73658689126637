// scroll bar
import 'simplebar/src/simplebar.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// COMPONENTS AND GLOBAL CONTEXT
import GlobalContextProvider from './context/global/globalContext';
import App from './App';

// ----------------------------------------------------------------------
const rootcontainer = document.getElementById('root');
const root = createRoot(rootcontainer);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </BrowserRouter>
  </HelmetProvider>
);
