/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

// material
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';

// components
import { useUpdateHistorical, useDeleteHistorical } from '../../services/historicalService';

// eslint-disable-next-line
const HistoricalActions = ({ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }) => {
  const queryClient = useQueryClient();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const editHistoricalSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['historical-data']);
      setRowId(null);
      setSuccessfulUpdate(true);
      setLoadingUpdate(false);
      setNotify({
        isOpen: true,
        message: 'Historical Update Successful',
        type: 'success',
      });
    }
  };

  const { mutate: updateHistorical } = useUpdateHistorical(editHistoricalSuccess);

  const onUpdate = async () => {
    setLoadingUpdate(true);
    // eslint-disable-next-line
    const {
      id,
      period,
      date_last_updated,
      date_created,
      historical_conflict_status,
      silo,
      active_asset_id,
      historical_asset_id,
      historical_conflicting_owner,
      historical_conflicting_country_code,
      resolved_in_favor_of,
      historical_customer,
      historical_ownership,
      action_type,
      otr_email_sent_date,
      action_taken,
      additional_info,
      conflicts_manager,
      main_data_composite,
      main_data_composite_wstatus,
      main_data_composite_active,
      main_data_composite_active_wstatus,
    } = params.row;

    await updateHistorical({
      id,
      period,
      date_last_updated,
      date_created,
      historical_conflict_status,
      silo,
      active_asset_id,
      historical_asset_id,
      historical_conflicting_owner,
      historical_conflicting_country_code,
      resolved_in_favor_of,
      historical_customer,
      historical_ownership,
      action_type,
      otr_email_sent_date,
      action_taken,
      additional_info,
      conflicts_manager,
      main_data_composite,
      main_data_composite_wstatus,
      main_data_composite_active,
      main_data_composite_active_wstatus,
    });

    setLoadingUpdate(false);
  };

  const deleteHistoricalSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['historical-data']);
      setNotify({
        isOpen: true,
        message: 'Delete Historical Successful',
        type: 'success',
      });
    }
  };

  const { mutate: deleteHistorical } = useDeleteHistorical(deleteHistoricalSuccess);

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteHistorical(id);
  };

  useEffect(() => {
    if (rowId === params.id && successfulUpdate) setSuccessfulUpdate(false);
    // eslint-disable-next-line
  }, [rowId]);

  return (
    <>
      <Box>
        {successfulUpdate ? (
          <IconButton
            color="primary"
            sx={{
              '&:hover': { bgcolor: green[100] },
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton color="primary" disabled={params.id !== rowId || loadingUpdate} onClick={onUpdate}>
            {rowId !== null ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        )}
        {loadingUpdate && (
          <CircularProgress
            size={51}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
        {
          <IconButton
            color="error"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this record?',
                subTitle: `Record: ${params.id}. You can't undo this operation.`,
                onConfirm: () => {
                  onDelete(params.id);
                },
                onReject: () => {
                  setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                  });
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      </Box>
    </>
  );
};

HistoricalActions.propTypes = {
  params: PropTypes.object,
  rowId: PropTypes.number,
  setRowId: PropTypes.func,
  setNotify: PropTypes.func,
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func
};

export default HistoricalActions;
