import { useMemo, useState } from 'react';

// material
import { Button, Box, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SortIcon from '@mui/icons-material/Sort';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import LinearProgress from '@mui/material/LinearProgress';

import { useCSVReader } from 'react-papaparse';

import { useQueryClient } from 'react-query';

// components
import { useBackPay, useAddBackPay, useAddBackPayBulk } from '../../services/backpayService';
import Page from '../../components/common/Page';
import BackPayForm from '../../components/backpay/backpayForm';
import { useGlobalState } from '../../context/global/globalContext';

// formcomponents
import Popup from '../../components/sections/form/Popup';
import Notification from '../../components/sections/form/Notification';
import ConfirmDialog from '../../components/sections/form/ConfirmDialog';

// ----------------------------------------------------------------------

const BackPays = () => {
  const queryClient = useQueryClient();

  const { CSVReader } = useCSVReader();

  const {
    state: { currentUser },
  } = useGlobalState();

  const [backpayNew, setBackPayNew] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const backpaySuccess = (data) => {
    setRowCountState(data.count);
    setNotify({
      isOpen: true,
      message: 'BackPay Data Refreshed',
      type: 'success',
    });
  };

  const addBackPaySuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['backpay-data']);
      setBackPayNew(null);
      setOpenPopup(false);
      setNotify({
        isOpen: true,
        message: 'BackPay Insert Successful',
        type: 'success',
      });
    }
  };

  const addBackPaySuccessBulk = (data) => {
    if (data) {
      queryClient.invalidateQueries(['backpay-data']);
      setNotify({
        isOpen: true,
        message: 'CSV Data Upload Successful',
        type: 'success',
      });
    }
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10000,
    page: 0,
  });

  const { isLoading, isInitialLoading, data: BackPays, isError, error, refetch } = useBackPay(backpaySuccess, { page: parseInt(paginationModel.page, 10), pageSize: parseInt(paginationModel.pageSize, 10) });

  const { mutate: addBackPay } = useAddBackPay(addBackPaySuccess);
  const { mutate: addBackPayBulk } = useAddBackPayBulk(addBackPaySuccessBulk);

  const addBackPayRecord = async (backpay, resetForm) => {
    backpay.active_asset_id = backpay.backpay_asset_id;
    backpay.userId = currentUser?.username;
    await addBackPay(backpay);
    await resetForm();
  };

  const addBackPayBulkRecords = async (backpay, clean) => {
    const uname = currentUser?.username;
    await addBackPayBulk({ backpay, clean, uname });
  };

  const [rowCountState, setRowCountState] = useState(100);

  const handlePageChange = (newPage) => {
    setPaginationModel({ ...paginationModel, page: newPage });
  }

  const [rowId, setRowId] = useState(null);

  const [sortModel, setSortModel] = useState([
    {
      field: 'backpay_period',
      sort: 'desc',
    },
  ]);

  const resetSort = () => {
    setSortModel([
      {
        field: 'backpay_period',
        sort: 'desc',
      },
    ]);
  };

  const defaultVisibility = {
    id: false,
    backpay_silo: true,
    backpay_period: true,
    backpay_asset_id: true,
    active_asset_id: true,
    ownership: true,
    backpay_asset_type: true,
    backpay_artist: true,
    backpay_asset_title: true,
    backpay_custom_id: true,
    backpay_isrc: true,
    backpay_upc: true,
    backpay_gRid: false,
    backpay_label: true,
    administer_publish_rights: false,
    content_type: true,
    claim_type: true,
    record_type: true,
    total_views: true,
    ad_enabled_views: false,
    watch_page_views: false,
    embedded_page_views: false,
    channel_page_video_views: false,
    total_earnings: true,
    gross_youtube_sold_revenue: false,
    gross_partner_sold_revenue: false,
    gross_adSense_sold_revenue: false,
    net_youtube_sold_revenue: false,
    net_adsense_sold_revenue: false,
    aresAssetsDetailId: true,
  };

  const customVisibility = {
    id: false,
    backpay_silo: true,
    backpay_period: true,
    backpay_asset_id: false,
    active_asset_id: true,
    ownership: true,
    backpay_asset_type: true,
    backpay_artist: false,
    backpay_asset_title: false,
    backpay_custom_id: false,
    backpay_isrc: false,
    backpay_upc: false,
    backpay_gRid: false,
    backpay_label: false,
    administer_publish_rights: false,
    content_type: true,
    claim_type: true,
    record_type: true,
    total_views: true,
    ad_enabled_views: false,
    watch_page_views: false,
    embedded_page_views: false,
    channel_page_video_views: false,
    total_earnings: true,
    gross_youtube_sold_revenue: false,
    gross_partner_sold_revenue: false,
    gross_adSense_sold_revenue: false,
    net_youtube_sold_revenue: false,
    net_adsense_sold_revenue: false,
    aresAssetsDetailId: false,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultVisibility);
  const [columnVisibilityToggle, setColumnVisibilityToggle] = useState(false);

  useMemo(() => {
    // eslint-disable-next-line
    const newColumns = columnVisibilityToggle === true ? customVisibility : defaultVisibility;
    setColumnVisibilityModel(newColumns);
    // eslint-disable-next-line
  }, [columnVisibilityToggle]);

  const toggleDefaults = () => {
    setColumnVisibilityToggle(!columnVisibilityToggle);
  };

  const csvfileprocess = (results, file) => {
    setConfirmDialog({
      isOpen: true,
      title: `Do you want to upload the file ${file.name}?`,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: true,
          title: `Do you want to remove existing data?`,
          onConfirm: () => {
            addBackPayBulkRecords(results, true);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
          onReject: () => {
            addBackPayBulkRecords(results, false);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
        });
      },
      onReject: () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      },
    });
  };

  // CONFIG INFO: https://www.papaparse.com/docs#config
  const parseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: (h) => {
      return h.replace(/ /g, '_').replace(/-/g, '_').replace('/', '_').toLowerCase();
    },
    dynamicTyping: true,
    preview: 0,
    encoding: 'utf-8',
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '  ', '\t', '|', ';'],
  };

  const BackPayHeaders = useMemo(
    () => [
      {
        field: 'backpay_silo',
        headerName: 'Silo',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['AdShare MG', 'AdShare Third Party'],
      },
      {
        field: 'backpay_period',
        headerName: 'Report Period',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'date',
      },
      {
        field: 'active_asset_id',
        headerName: 'Active Asset ID',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://studio.youtube.com/asset/${params.row.active_asset_id}/ownership?o=UQqssfZa6RRkJ8_LzjrWEA`} target='_blank'>{params.row.active_asset_id}</a>
        ),
      },
      {
        field: 'backpay_asset_id',
        headerName: 'Asset ID',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://studio.youtube.com/asset/${params.row.backpay_asset_id}/ownership?o=UQqssfZa6RRkJ8_LzjrWEA`} target='_blank'>{params.row.backpay_asset_id}</a>
        ),
      },
      {
        field: 'ownership',
        headerName: 'Ownership',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_asset_type',
        headerName: 'Asset Type',
        width: 220,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['MUSIC_VIDEO', 'ART_TRACK_VIDEO', 'SOUND_RECORDING', 'WEB', 'COMPOSITION'],
      },
      {
        field: 'backpay_artist',
        headerName: 'Artist',
        width: 220,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_asset_title',
        headerName: 'Asset Title',
        width: 220,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_custom_id',
        headerName: 'Custom ID',
        width: 220,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_isrc',
        headerName: 'ISRC',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_upc',
        headerName: 'UPC',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_gRid',
        headerName: 'GRID',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_label',
        headerName: 'Label',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'administer_publish_rights',
        headerName: 'Administer Publish Rights',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['yes', 'no'],
      },
      {
        field: 'content_type',
        headerName: 'Content Type',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['USER_GENERATED', 'LICENSED', 'PREMIUM_USER_GENERATED'],
      },
      {
        field: 'claim_type',
        headerName: 'Claim Type',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['AUDIO_VISUAL', 'VISUAL', 'AUDIO'],
      },
      {
        field: 'record_type',
        headerName: 'Record Type',
        width: 180,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'singleSelect',
        valueOptions: ['Conflict Resolution'],
      },
      {
        field: 'country',
        headerName: 'Territory',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'day',
        headerName: 'Revenue Period',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'date',
      },
      {
        field: 'total_views',
        headerName: 'Total Views',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'total_earnings',
        headerName: 'Total Earnings',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'ad_enabled_views',
        headerName: 'Ad Enabled Views',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'watch_page_views',
        headerName: 'Watch Page Views',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'embedded_page_views',
        headerName: 'Embedded Page Views',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'channel_page_video_views',
        headerName: 'Channel Page Video Views',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'gross_youtube_sold_revenue',
        headerName: 'Gross Youtube Sold Revenue',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'gross_partner_sold_revenue',
        headerName: 'Gross Partner Sold Revenue',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'gross_adSense_sold_revenue',
        headerName: 'Gross AdSense Sold Revenue',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'net_youtube_sold_revenue',
        headerName: 'Net Youtube Sold Revenue',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'net_adsense_sold_revenue',
        headerName: 'Net Adsense Sold Revenue',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'aresAssetsDetailId',
        headerName: 'Asset Details ID',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
    ],
    // eslint-disable-next-line
    [rowId]
  );

  let content;
  if (isInitialLoading) {
    content = <LinearProgress />;
  } else if (isError) {
    content = (
      <Alert severity="error">
        <AlertTitle>
          BackPay Data failed to load. Please logout, clear cache, re-login and try this page again.
        </AlertTitle>
        {error.message}
      </Alert>
    );
  } else {
    return (
      <>
        <Page title="BackPay">
          <Box
            sx={{
              height: 700,
              width: '100%',
            }}
          >
            <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
              BackPay Revenue for AdShare MG and AdShare Third Party
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ maxHeight: '1.8rem', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography color={'error'} variant="body1" sx={{ flexGrow: 1 }}>
                {BackPays?.count ? '' : 'Loading BackPay Revenue. Please wait for a few seconds...'}
              </Typography>

              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                // endIcon={<SortIcon />}
                onClick={resetSort}
              >
                Default Sort
              </Button>
              <Button
                variant="outlined"
                startIcon={<VisibilityIcon />}
                // endIcon={<VisibilityIcon />}
                onClick={toggleDefaults}
              >
                {columnVisibilityToggle === true ? 'Default View' : 'Compact View'}
              </Button>
              <Button variant="outlined" startIcon={<RefreshSharpIcon />} onClick={refetch}>
                Refresh
              </Button>
              <Button
                variant="outlined"
                startIcon={<PostAddIcon />}
                onClick={() => {
                  setBackPayNew(null);
                  setOpenPopup(true);
                }}
              >
                Add New
              </Button>
              <CSVReader
                config={parseConfig}
                accept="text/csv, .csv, application/vnd.ms-excel"
                onUploadAccepted={(results, file) => csvfileprocess(results, file)}
              >
                {({ getRootProps }) => ( // acceptedFile, ProgressBar, getRemoveFileProps, Remove
                  <>
                    <Button variant="outlined" component="label" startIcon={<DescriptionIcon />} {...getRootProps()}>
                      {' '}
                      Upload CSV
                    </Button>
                  </>
                )}
              </CSVReader>
            </Stack>
            <DataGrid
              loading={isLoading}
              columns={BackPayHeaders}
              rows={!isLoading && BackPays.rows}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              onPageSizeChange={(newPageSize) => setPaginationModel({ ...paginationModel, pageSize: newPageSize })}
              onPageChange={handlePageChange}
              paginationMode='server'
              paginationModel={paginationModel}
              rowCount={rowCountState}
              rowsPerPageOptions={[25, 50, 75, 100]}
              components={{ Toolbar: GridToolbar }}
              onCellEditCommit={(params) => setRowId(params.id)}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newVisibilityModel) => setColumnVisibilityModel(newVisibilityModel)}
            />
          </Box>
        </Page>
        <Popup title="Add New BackPay Record" openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <BackPayForm backpayNew={backpayNew} addBackPay={addBackPayRecord} />
        </Popup>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }

  return content;
}

export default BackPays;