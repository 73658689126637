import { useMemo, useState } from 'react';

// material
import { Button, Box, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SortIcon from '@mui/icons-material/Sort';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import LinearProgress from '@mui/material/LinearProgress';

import { useCSVReader } from 'react-papaparse';

import { useQueryClient } from 'react-query';

// components
import { useHistorical, useAddHistorical, useAddHistoricalBulk } from '../../services/historicalService';
import Page from '../../components/common/Page';
import HistoricalActions from '../../components/historical/historicalActions';
import HistoricalForm from '../../components/historical/historicalForm';
import { useGlobalState } from '../../context/global/globalContext';

// formcomponents
import Popup from '../../components/sections/form/Popup';
import Notification from '../../components/sections/form/Notification';
import ConfirmDialog from '../../components/sections/form/ConfirmDialog';

// ----------------------------------------------------------------------

const Historicals = () => {
  const queryClient = useQueryClient();

  const { CSVReader } = useCSVReader();

  const {
    state: { currentUser },
  } = useGlobalState();

  const [historicalNew, setHistoricalNew] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const historicalSuccess = (data) => {
    setRowCountState(data.count);
    setNotify({
      isOpen: true,
      message: 'Historical Data Refreshed',
      type: 'success',
    });
  };

  const addHistoricalSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['historical-data']);
      setHistoricalNew(null);
      setOpenPopup(false);
      setNotify({
        isOpen: true,
        message: 'Historical Insert Successful',
        type: 'success',
      });
    }
  };

  const addHistoricalSuccessBulk = (data) => {
    if (data) {
      queryClient.invalidateQueries(['historical-data']);
      setNotify({
        isOpen: true,
        message: 'CSV Data Upload Successful',
        type: 'success',
      });
    }
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10000,
    page: 0,
  });

  const { isLoading, isInitialLoading, data: Historicals, isError, error, refetch } = useHistorical(historicalSuccess, { page: parseInt(paginationModel.page, 10), pageSize: parseInt(paginationModel.pageSize, 10) });

  const { mutate: addHistorical } = useAddHistorical(addHistoricalSuccess);
  const { mutate: addHistoricalBulk } = useAddHistoricalBulk(addHistoricalSuccessBulk);

  const addHistoricalRecord = async (historical, resetForm) => {
    historical.active_asset_id = historical.historical_asset_id;
    historical.userId = currentUser?.username;
    await addHistorical(historical);
    await resetForm();
  };

  const addHistoricalBulkRecords = async (historicals, clean) => {
    const uname = currentUser?.username;
    await addHistoricalBulk({ historicals, clean, uname });
  };

  const [rowCountState, setRowCountState] = useState(100);

  const handlePageChange = (newPage) => {
    setPaginationModel({ ...paginationModel, page: newPage });
  }

  const [rowId, setRowId] = useState(null);

  const [sortModel, setSortModel] = useState([
    {
      field: 'active_asset_id',
      sort: 'asc',
    },
  ]);

  const resetSort = () => {
    setSortModel([
      {
        field: 'active_asset_id',
        sort: 'asc',
      },
    ]);
  };

  const defaultVisibility = {
    id: false,
    period: true,
    date_last_updated: false,
    date_created: false,
    historical_conflict_status: true,
    silo: true,
    active_asset_id: true,
    historical_asset_id: true,
    historical_conflicting_owner: true,
    historical_conflicting_country_code: true,
    resolved_in_favor_of: true,
    historical_customer: true,
    historical_ownership: true,
    action_type: true,
    otr_email_sent_date: true,
    action_taken: true,
    additional_info: true,
    conflicts_manager: true,
    aresAssetsDetailId: false,
  };

  const customVisibility = {
    id: false,
    period: true,
    date_last_updated: false,
    date_created: false,
    historical_conflict_status: true,
    silo: true,
    active_asset_id: true,
    historical_asset_id: false,
    historical_conflicting_owner: true,
    historical_conflicting_country_code: false,
    resolved_in_favor_of: true,
    historical_customer: true,
    historical_ownership: false,
    action_type: true,
    otr_email_sent_date: true,
    action_taken: true,
    additional_info: true,
    conflicts_manager: true,
    aresAssetsDetailId: false,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultVisibility);
  const [columnVisibilityToggle, setColumnVisibilityToggle] = useState(false);

  useMemo(() => {
    // eslint-disable-next-line
    const newColumns = columnVisibilityToggle === true ? customVisibility : defaultVisibility;
    setColumnVisibilityModel(newColumns);
    // eslint-disable-next-line
  }, [columnVisibilityToggle]);

  const toggleDefaults = () => {
    setColumnVisibilityToggle(!columnVisibilityToggle);
  };

  const csvfileprocess = (results, file) => {
    setConfirmDialog({
      isOpen: true,
      title: `Do you want to upload the file ${file.name}?`,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: true,
          title: `Do you want to remove existing data?`,
          onConfirm: () => {
            addHistoricalBulkRecords(results, true);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
          onReject: () => {
            addHistoricalBulkRecords(results, false);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
        });
      },
      onReject: () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      },
    });
  };

  // CONFIG INFO: https://www.papaparse.com/docs#config
  const parseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: (h) => {
      return h.replace(/ /g, '_').replace(/-/g, '_').replace('/', '_').toLowerCase();
    },
    dynamicTyping: true,
    preview: 0,
    encoding: 'utf-8',
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '	', '\t', '|', ';'],
  };

  const HistoricalHeaders = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
        disableExport: true,
      },
      {
        field: 'period',
        headerName: 'Period',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'date',
      },
      {
        field: 'date_last_updated',
        headerName: 'Last Updated',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'date',
      },
      {
        field: 'date_created',
        headerName: 'First Conflicted Date',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'date',
      },
      {
        field: 'historical_conflict_status',
        headerName: 'Conflict Status',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Still in Conflict', 'Resolved'],
      },
      {
        field: 'silo',
        headerName: 'Silo',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['AdShare MG', 'AdShare Third Party'],
      },
      {
        field: 'active_asset_id',
        headerName: 'Active Asset ID',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://studio.youtube.com/asset/${params.row.active_asset_id}/ownership?o=UQqssfZa6RRkJ8_LzjrWEA`} target='_blank'>{params.row.active_asset_id}</a>
        ),
      },
      {
        field: 'historical_asset_id',
        headerName: 'Asset ID',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://studio.youtube.com/asset/${params.row.historical_asset_id}/ownership?o=UQqssfZa6RRkJ8_LzjrWEA`} target='_blank'>{params.row.historical_asset_id}</a>
        ),
      },
      {
        field: 'historical_conflicting_owner',
        headerName: 'Conflicting Owner',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
      },
      {
        field: 'historical_conflicting_country_code',
        headerName: 'Conflicting Country Code',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
      },
      {
        field: 'resolved_in_favor_of',
        headerName: 'Resolved in Favor Of',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: [
          'In Conflicted Stage',
          'Resolved in favor of AdShare',
          'Resolved in favor of Third Party',
          'Status Pending',
          'Dummy Resolved',
        ],
      },
      {
        field: 'historical_customer',
        headerName: 'Customer',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://thekraken.godigitalmg.com/networks-customers/customers?search=name&value=${params.row.historical_customer ? params.row.historical_customer.toLowerCase() : ''}`} target='_blank'>{params.row.historical_customer}</a>
        ),
      },
      {
        field: 'historical_ownership',
        headerName: 'Ownership',
        width: 180,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Ownership Exists', 'Ownership Removed'],
      },
      {
        field: 'action_type',
        headerName: 'Action Type',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['OTR In', 'OTR Out', 'Email In', 'Email Out', 'Other'],
      },
      {
        field: 'otr_email_sent_date',
        headerName: 'OTR Email Sent Date',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'date',
      },
      {
        field: 'action_taken',
        headerName: 'Action Taken',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Pending', 'Accepted', 'Rejected', 'Resolved', 'Other'],
      },
      {
        field: 'additional_info',
        headerName: 'Additional Info',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
      },
      {
        field: 'conflicts_manager',
        headerName: 'Conflicts Manager',
        width: 120,
        sortable: true,
        filterable: true,
        editable: true,
      },
      {
        field: 'aresAssetsDetailId',
        headerName: 'Asset Details ID',
        width: 120,
        sortable: true,
        filterable: true,
        disableExport: true,
        editable: true,
        type: 'number',
      },
      {
        field: 'actions',
        headerName: 'Options',
        width: 200,
        sortable: false,
        filterable: false,
        hideable: false,
        disableExport: true,
        type: 'actions',
        renderCell: (params) => (
          // eslint-disable-next-line
          <HistoricalActions {...{ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }} />
        ),
      },
    ],
    // eslint-disable-next-line
    [rowId]
  );

  let content;
  if (isInitialLoading) {
    content = <LinearProgress />;
  } else if (isError) {
    content = (
      <Alert severity="error">
        <AlertTitle>
          Historical Data failed to load. Please logout, clear cache, re-login and try this page again.
        </AlertTitle>
        {error.message}
      </Alert>
    );
  } else {
    return (
      <>
        <Page title="Historical">
          <Box
            sx={{
              height: 700,
              width: '100%',
            }}
          >
            <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
              Historical Conflict Records for AdShare MG and AdShare Third Party
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ maxHeight: '1.8rem', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography color={'error'} variant="body1" sx={{ flexGrow: 1 }}>
                {Historicals?.count ? '' : 'Loading Historical Conflicts. Please wait for a few seconds...'}
              </Typography>

              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                // endIcon={<SortIcon />}
                onClick={resetSort}
              >
                Default Sort
              </Button>
              <Button
                variant="outlined"
                startIcon={<VisibilityIcon />}
                // endIcon={<VisibilityIcon />}
                onClick={toggleDefaults}
              >
                {columnVisibilityToggle === true ? 'Default View' : 'Compact View'}
              </Button>
              <Button variant="outlined" startIcon={<RefreshSharpIcon />} onClick={refetch}>
                Refresh
              </Button>
              <Button
                variant="outlined"
                startIcon={<PostAddIcon />}
                // endIcon={<PostAddIcon />}
                onClick={() => {
                  setHistoricalNew(null);
                  setOpenPopup(true);
                }}
              >
                Add New
              </Button>
              <CSVReader
                config={parseConfig}
                accept="text/csv, .csv, application/vnd.ms-excel"
                onUploadAccepted={(results, file) => csvfileprocess(results, file)}
              >
                {({ getRootProps }) => ( // acceptedFile, ProgressBar, getRemoveFileProps, Remove
                  <>
                    <Button variant="outlined" component="label" startIcon={<DescriptionIcon />} {...getRootProps()}>
                      {' '}
                      Upload CSV
                    </Button>
                  </>
                )}
              </CSVReader>
            </Stack>
            <DataGrid
              loading={isLoading}
              columns={HistoricalHeaders}
              rows={!isLoading && Historicals.rows}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              onPageSizeChange={(newPageSize) => setPaginationModel({ ...paginationModel, pageSize: newPageSize })}
              onPageChange={handlePageChange}
              paginationMode='server'
              paginationModel={paginationModel}
              rowCount={rowCountState}
              rowsPerPageOptions={[25, 50, 75, 100]}
              components={{ Toolbar: GridToolbar }}
              onCellEditCommit={(params) => setRowId(params.id)}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newVisibilityModel) => setColumnVisibilityModel(newVisibilityModel)}
            />
          </Box>
        </Page>
        <Popup title="Add New Historical Record" openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <HistoricalForm historicalNew={historicalNew} addHistorical={addHistoricalRecord} />
        </Popup>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }

  return content;
}

export default Historicals;