// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';

// hooks
import useResponsive from '../../components/common/useResponsive';

// components
import Page from '../../components/common/Page';

// sections
import AuthGoogle from './auth/AuthGoogle';

window.localStorage.clear();
window.sessionStorage.clear();

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// STYLES ----------------------------------------------------------------------

const Login = () => {
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Rights Management Sign In
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Click below to Sign In</Typography>

            <AuthGoogle />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default Login;