import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// sections
import Iconify from '../common/Iconify';
import { ScorecardSet, SelectCard } from '../sections/dashboard/templates';

import { useTotalRevenue, useTotalUSViews, useTotalView, useGrossSold, useBackPayMonths } from '../../services/backpayService';
import { useCustomerNames } from '../../services/customerService';

const HistoricalBackPayStatsCard = ({ period = '*', customer = '*', setPeriod, setCustomer }) => {
  const initialLoading = { dataset: [{ id: 'Loading..', title: 'Loading..' }] };
  const [customerList, setCustomerList] = useState(initialLoading);
  const [periodList, setPeriodList] = useState(initialLoading);

  const { data: totalrevenue } = useTotalRevenue();
  const { data: totalusviews } = useTotalUSViews();
  const { data: totalviews } = useTotalView();
  const { data: grosssold } = useGrossSold();

  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      (x) => {
        const pmx = primer(x[field])
        return pmx
      } :
      (x) => {
        const pmx = x[field]
        return pmx
      };

    reverse = !reverse ? 1 : -1;

    return (a, b) => {
      const ae = (a = key(a), b = key(b), reverse * ((a > b) - (b > a)));
      return ae
    }
  }

  const customernameSuccess = (data) => {
    if (data) {
      const clist = {
        dataset: [
          ...new Set(
            data.map((item) => {
              return { id: item.id, title: item.id };
            })
          ),
        ],
      };
      clist.dataset.sort(sort_by('id', false, (a) => a.toUpperCase()));
      clist.dataset.unshift({ id: 'Unassigned', title: 'Unassigned' });
      setCustomerList(clist);
    }
  };

  const periodSuccess = (data) => {
    if (data) {
      const clist = {
        dataset: [
          ...new Set(
            data.map((item) => {
              return { id: item.id, title: item.id };
            })
          ),
        ],
      };
      clist.dataset.sort(sort_by('id', false, (a) => a.toUpperCase()));
      clist.dataset.unshift({ id: 'Unassigned', title: 'Unassigned' });
      setPeriodList(clist);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (customer !== '*') {
      try {
        setCustomerList({
          dataset: customer
        })
      } catch (e) { console.log(e) }
      // eslint-disable-next-line
      customer = '*';
    }
    // eslint-disable-next-line
    if (period !== '*') {
      try {
        setPeriodList({
          dataset: period
        })
      } catch (e) { console.log(e) }
      // eslint-disable-next-line
      period = '*';
    }
  }, [])

  // eslint-disable-next-line
  const { data: BackpayCustomerNames } = useCustomerNames(customernameSuccess);

  // eslint-disable-next-line
  const { data: BackpayDates } = useBackPayMonths(periodSuccess);

  return (
    <>
      <SelectCard
        header="Revenue Period"
        items={periodList}
        value={period}
        setValue={setPeriod}
        bottomMargin={'3px'}
      />
      <SelectCard
        header="Customer"
        items={customerList}
        value={customer}
        setValue={setCustomer}
        bottomMargin={'5px'}
      />
      <Box
        sx={{
          width: '1px%',
          height: '11px',
        }}
      />
      <ScorecardSet
        title="Lifetime BackPay Stats"
        list={[
          {
            name: 'Total Global Views',
            value: totalviews,
            icon: (
              <Iconify
                color="#1877F2"
                width={32}
                height={32}
              />
            ),
          },
          {
            name: 'Total US Views',
            value: totalusviews,
            icon: <Iconify color="#DF3E30" width={32} height={32} />,
          },
          {
            name: 'Total Earnings',
            value: totalrevenue,
            icon: <Iconify color="#006097" width={32} height={32} />,
          },
          {
            name: 'Gross AdSense Sold',
            value: grosssold,
            icon: <Iconify color="#1C9CEA" width={32} height={32} />,
          },
        ]}
      />
    </>
  );
}

HistoricalBackPayStatsCard.propTypes = {
  period: PropTypes.any,
  customer: PropTypes.any,
  setPeriod: PropTypes.func,
  setCustomer: PropTypes.func,
};

export default HistoricalBackPayStatsCard;
