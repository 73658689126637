import { useMemo, useState } from 'react';

// material
import { Grid, Container, Button, Box, Stack, Alert, AlertTitle, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SortIcon from '@mui/icons-material/Sort';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import LinearProgress from '@mui/material/LinearProgress';

import { useQueryClient } from 'react-query';

// components
import { useAddCustomer } from '../../services/customerService';
import { useBackPayDashboardFeed } from '../../services/backpayService';

import Page from '../../components/common/Page';
import CustomerForm from '../../components/customer/customerForm';
import { useGlobalState } from '../../context/global/globalContext';
import HistoricalStatsChart from '../../components/backpaydashboard/historicalStatsChart';
import HistoricalBackPayStatsCard from '../../components/backpaydashboard/historicalBackPayStatsCard';

// formcomponents
import Popup from '../../components/sections/form/Popup';
import Notification from '../../components/sections/form/Notification';
import ConfirmDialog from '../../components/sections/form/ConfirmDialog';

import axiosInstance from '../../config/axios-common';
// ----------------------------------------------------------------------

const BackpayRevenues = () => {

  const [customer, setCustomer] = useState('*');
  const [period, setPeriod] = useState('*');

  const queryClient = useQueryClient();

  const {
    state: { currentUser },
  } = useGlobalState();

  try {
    const axios_token = JSON.parse(localStorage.getItem('currentUser')).token;
    axiosInstance.defaults.headers.common.authorization = axios_token;
  } catch (e) {
    console.log(e);
  }

  const [pageSize, setPageSize] = useState(25);

  const [rowId, setRowId] = useState(null);
  // const [rowassetId, setRowassetId] = useState(null);

  const [customerNew, setCustomerNew] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const customerSuccess = () => { };

  const { isRefetching, isFetching, isLoading, data: BackpayRevenues, isError, error, refetch } = useBackPayDashboardFeed(customerSuccess);

  useMemo(() => {
    if (BackpayRevenues && BackpayRevenues.length > 0) {
      setNotify({
        isOpen: true,
        message: 'Backpay Revenue Data Refreshed',
        type: 'success',
      })
    }
  }, [BackpayRevenues]);

  useMemo(() => {
    if (period !== '*') {
      setNotify({
        isOpen: true,
        message: `Filtering to ${period}.`,
        type: 'warning',
      })
    }
  }, [period]);

  useMemo(() => {
    if (customer !== '*') {
      setNotify({
        isOpen: true,
        message: `Filtering to ${customer}.`,
        type: 'warning',
      })
    }
  }, [customer]);

  const addCustomerSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['backpay-dashboard-data']);
      setCustomerNew(null);
      setOpenPopup(false);
      setNotify({
        isOpen: true,
        message: 'Customer Insert Successful',
        type: 'success',
      });
    }
  };



  const { mutate: addCustomer } = useAddCustomer(addCustomerSuccess);

  const addCustomerRecord = async (customer, resetForm) => {
    customer.userId = currentUser?.username;
    // customer.token = currentUser?.token;
    await addCustomer(customer);
    await resetForm();
  };

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const resetSort = () => {
    setSortModel([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
  };

  const generateRandom = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; i += 1) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const defaultVisibility = {
    historical_customer: true,
    backpay_silo: true,
    backpay_period: true,
    day: true,
    asset_id: true,
    content_type: true,
    claim_type: true,
    backpay_asset_type: true,
    total_views: true,
    total_earnings: true,
    gross_adsense_sold_revenue: true,
  };

  const customVisibility = {
    historical_customer: true,
    backpay_silo: true,
    backpay_period: true,
    asset_id: true,
    day: false,
    content_type: false,
    claim_type: false,
    backpay_asset_type: false,
    total_views: true,
    total_earnings: true,
    gross_adsense_sold_revenue: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultVisibility);
  const [columnVisibilityToggle, setColumnVisibilityToggle] = useState(false);

  useMemo(() => {
    // eslint-disable-next-line
    const newColumns = columnVisibilityToggle === true ? customVisibility : defaultVisibility;
    setColumnVisibilityModel(newColumns);
    // eslint-disable-next-line
  }, [columnVisibilityToggle]);

  const toggleDefaults = () => {
    setColumnVisibilityToggle(!columnVisibilityToggle);
  };

  const BackpayRevenueHeaders = useMemo(
    () => [
      {
        field: 'historical_customer',
        headerName: 'Customer',
        width: 220,
        sortable: true,
        filterable: true,
        flex: 1,
        editable: false,
        disableExport: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://thekraken.godigitalmg.com/networks-customers/customers?search=name&value=${params.row.historical_customer ? params.row.historical_customer.toLowerCase() : ''}`} target='_blank'>{params.row.historical_customer}</a>
        ),
      },
      {
        field: 'backpay_silo',
        headerName: 'Silo',
        width: 120,
        sortable: true,
        filterable: true,
        flex: 1,
        editable: false,
      },
      {
        field: 'backpay_period',
        headerName: 'Report Period',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'date',
      },
      {
        field: 'day',
        headerName: 'Revenue Period',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'date',
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        width: 220,
        flex: 1,
        sortable: true,
        filterable: true,
        editable: false,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://studio.youtube.com/asset/${params.row.asset_id}/ownership?o=UQqssfZa6RRkJ8_LzjrWEA`} target='_blank'>{params.row.asset_id}</a>
        ),
      },
      {
        field: 'content_type',
        headerName: 'Content Type',
        width: 120,
        flex: 1,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'claim_type',
        headerName: 'Claim Type',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'backpay_asset_type',
        headerName: 'Asset Type',
        width: 120,
        sortable: true,
        filterable: true,
        editable: false,
      },
      {
        field: 'total_views',
        headerName: 'Total Views',
        width: 100,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'total_earnings',
        headerName: 'Total Earnings',
        width: 100,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
      {
        field: 'gross_adsense_sold_revenue',
        headerName: 'Gross AdSense Sold',
        width: 100,
        sortable: true,
        filterable: true,
        editable: false,
        type: 'number',
      },
    ],
    // eslint-disable-next-line
    [rowId]
  );

  let content;
  if (isLoading || isRefetching || isFetching) {
    content = <Grid item xs={12} md={12} lg={12}><LinearProgress /></Grid>;
  } else if (isError) {
    content = (
      <Grid item xs={12} md={12} lg={12}>
        <Alert severity="error">
          <AlertTitle>
            BackPay Revenue Data failed to load. Please logout, clear cache, re-login and try again.
          </AlertTitle>
          {error.message}
        </Alert>
      </Grid>
    );
    // <Alert severity="error">{error.message}</Alert>;
  } else {
    content =
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            height: 700,
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ maxHeight: '1.8rem', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Typography color={'error'} variant="body1" sx={{ flexGrow: 1 }}>
              {BackpayRevenues.length >= 1 ? '' : 'Loading Aggregated BackPay Revenue. Please wait for a few seconds...'}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<SortIcon />}
              // endIcon={<NoteAddIcon />}
              onClick={resetSort}
            >
              Default Sort
            </Button>
            <Button
              variant="outlined"
              startIcon={<VisibilityIcon />}
              // endIcon={<NoteAddIcon />}
              onClick={toggleDefaults}
            >
              {columnVisibilityToggle === true ? 'Default View' : 'Compact View'}
            </Button>
            <Button variant="outlined" startIcon={<RefreshSharpIcon />} onClick={refetch}>
              Refresh
            </Button>
            <Button
              variant="outlined"
              startIcon={<PostAddIcon />}
              onClick={() => {
                setCustomerNew(null);
                setOpenPopup(true);
              }}
            >
              Add New
            </Button>
          </Stack>
          <DataGrid
            columns={BackpayRevenueHeaders}
            rows={BackpayRevenues}
            // filterMode="server"
            // sortingMode="server"
            getRowHeight={() => 'auto'}
            getRowId={() => generateRandom()}
            rowsPerPageOptions={[25, 50, 75, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
            onCellEditCommit={(params) => { setRowId(params.id) }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newVisibilityModel) => setColumnVisibilityModel(newVisibilityModel)}
          />
        </Box>
      </Grid>
  }

  // return content;
  return (
    <>
      <Page title="Backpay Dashboard">
        <Container
          maxWidth={false}
        // maxWidth="xl"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={9}>
              <HistoricalStatsChart period={period} customer={customer} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <HistoricalBackPayStatsCard period={period} customer={customer} setPeriod={setPeriod} setCustomer={setCustomer} />
            </Grid>
            {content}
          </Grid>
        </Container>
      </Page>
      <Popup title="Add New Customer Record" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <CustomerForm customerNew={customerNew} addCustomer={addCustomerRecord} />
      </Popup>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default BackpayRevenues;