import axios from 'axios';
import { useRef, useState } from 'react';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton } from '@mui/material';

// components
import { useGlobalState } from '../../context/global/globalContext';
import MenuPopover from '../../components/common/MenuPopover';

const AccountPopover = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalState();

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logoutUser = () => {
    handleClose();
    const logoutUser = async () => {
      try {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: 'UPDATE_USER', payload: null });
        const url = `${process.env.REACT_APP_API_URL}/auth/api/logout`;
        await axios.get(url, { withCredentials: true, credentials: "include" });
      } catch (err) {
        console.log('User logged out');
      }
    };
    logoutUser();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={currentUser?.photoURL} alt={currentUser?.username}>
          {currentUser?.username?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser?.user_email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logoutUser} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default AccountPopover;