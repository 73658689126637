// @mui
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../common/formatNumber';
// components
// import Iconify from '../../../common/Iconify';

// ----------------------------------------------------------------------

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
// }));

// ----------------------------------------------------------------------



const ScoreCard = ({
  title,
  total,
  color = 'primary',
  textcolor = null,
  backcolor = null,
  sx,
  ...other
}) => {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => (theme.palette[color].darker ? theme.palette[color].darker : theme.palette[color][textcolor]),
        bgcolor: (theme) =>
          theme.palette[color].lighter ? theme.palette[color].lighter : theme.palette[color][backcolor],
        ...sx,
      }}
      {...other}
    >
      {/* <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle> */}

      <Typography variant="h3">{fShortenNumber(total)}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}

ScoreCard.propTypes = {
  color: PropTypes.string,
  textcolor: PropTypes.string,
  backcolor: PropTypes.string,
  // icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default ScoreCard;