import PropTypes from 'prop-types';

// sections
import { HorizontalBarChart } from '../sections/dashboard/templates';



const HistoricalCounterPartyBarChart = ({ useTopTenCPs }) => {
  const { data: toptencounterparties } = useTopTenCPs();
  return (
    <HorizontalBarChart
      title="Top 10 Highest Conflicting Counter Party"
      subheader=""
      chartData={toptencounterparties}
    />
  );
}

HistoricalCounterPartyBarChart.propTypes = {
  useTopTenCPs: PropTypes.func,
};

export default HistoricalCounterPartyBarChart;