/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// material
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';

// components
import { useUpdateRPM, useDeleteRPM } from '../../services/rpmService';

const RPMActions = ({ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }) => {
  const queryClient = useQueryClient();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const editRPMSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['rpm-data']);
      setRowId(null);
      setSuccessfulUpdate(true);
      setLoadingUpdate(false);
      setNotify({
        isOpen: true,
        message: 'RPM Update Successful',
        type: 'success',
      });
    }
  };

  const { mutate: updateRPM } = useUpdateRPM(editRPMSuccess);

  const onUpdate = async () => {
    setLoadingUpdate(true);
    // eslint-disable-next-line
    const { id, silo, period, rpm_grade, conflicting_country_code, rpm_by_ownership_territory } = params.row;

    await updateRPM({
      id,
      silo,
      period,
      rpm_grade,
      conflicting_country_code,
      rpm_by_ownership_territory,
    });

    setLoadingUpdate(false);
  };

  const deleteRPMSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['rpm-data']);
      setNotify({
        isOpen: true,
        message: 'Delete RPM Successful',
        type: 'success',
      });
    }
  };

  const { mutate: deleteRPM } = useDeleteRPM(deleteRPMSuccess);

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteRPM(id);
  };

  useEffect(() => {
    if (rowId === params.id && successfulUpdate) setSuccessfulUpdate(false);
    // eslint-disable-next-line
  }, [rowId]);

  return (
    <>
      <Box>
        {successfulUpdate ? (
          <IconButton
            color="primary"
            sx={{
              '&:hover': { bgcolor: green[100] },
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton color="primary" disabled={params.id !== rowId || loadingUpdate} onClick={onUpdate}>
            {rowId !== null ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        )}
        {loadingUpdate && (
          <CircularProgress
            size={51}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
        {
          <IconButton
            color="error"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this record?',
                subTitle: `Record: ${params.id}. You can't undo this operation.`,
                onConfirm: () => {
                  onDelete(params.id);
                },
                onReject: () => {
                  setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                  });
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      </Box>
    </>
  );
};

RPMActions.propTypes = {
  params: PropTypes.object,
  rowId: PropTypes.number,
  setRowId: PropTypes.func,
  setNotify: PropTypes.func,
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func
};

export default RPMActions;
