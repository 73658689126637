import PropTypes from 'prop-types';

import { ScoreCard } from '../sections/dashboard/templates';

const ResolvedAssetsAdShareScorecard = ({ useResolvedAdShareCount, favor }) => {
  const { data: resolvedAdShare } = useResolvedAdShareCount();

  return (
    <ScoreCard
      title="AdShare Resolved"
      total={favor instanceof Object || favor === 'All' || favor === '*' || favor === 'Resolved in favor of AdShare' ? Number(resolvedAdShare) : 0}
      color="success"
      icon={'ant-design:android-filled'}
    />
  );
}

ResolvedAssetsAdShareScorecard.propTypes = {
  favor: PropTypes.object,
  useResolvedAdShareCount: PropTypes.func,
};

export default ResolvedAssetsAdShareScorecard;