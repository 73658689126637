/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

// material
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';

// components
import { useUpdateCustomer, useDeleteCustomer } from '../../services/customerService';

// eslint-disable-next-line
const CustomerActions = ({ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }) => {
  const queryClient = useQueryClient();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const editCustomerSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['customer-data']);
      setRowId(null);
      setSuccessfulUpdate(true);
      setLoadingUpdate(false);
      setNotify({
        isOpen: true,
        message: 'Customer Update Successful',
        type: 'success',
      });
    }
  };

  const { mutate: updateCustomer } = useUpdateCustomer(editCustomerSuccess);

  const onUpdate = async () => {
    setLoadingUpdate(true);
    // eslint-disable-next-line
    const { id, customer_id, customer, customer_code_6_digit, customer_code, customer_normalized } = params.row;

    await updateCustomer({
      id,
      customer_id,
      customer,
      customer_code_6_digit,
      customer_code,
      customer_normalized,
    });

    setLoadingUpdate(false);
  };

  const deleteCustomerSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['customer-data']);
      setNotify({
        isOpen: true,
        message: 'Delete Customer Successful',
        type: 'success',
      });
    }
  };

  const { mutate: deleteCustomer } = useDeleteCustomer(deleteCustomerSuccess);

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteCustomer(id);
  };

  useEffect(() => {
    if (rowId === params.id && successfulUpdate) setSuccessfulUpdate(false);
    // eslint-disable-next-line
  }, [rowId]);

  return (
    <>
      <Box>
        {successfulUpdate ? (
          <IconButton
            color="primary"
            sx={{
              '&:hover': { bgcolor: green[100] },
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton color="primary" disabled={params.id !== rowId || loadingUpdate} onClick={onUpdate}>
            {rowId !== null ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        )}
        {loadingUpdate && (
          <CircularProgress
            size={51}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
        {
          <IconButton
            color="error"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this record?',
                subTitle: `Record: ${params.id}. You can't undo this operation.`,
                onConfirm: () => {
                  onDelete(params.id);
                },
                onReject: () => {
                  setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                  });
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      </Box>
    </>
  );
};

CustomerActions.propTypes = {
  params: PropTypes.object,
  rowId: PropTypes.number,
  setRowId: PropTypes.func,
  setNotify: PropTypes.func,
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func
};

export default CustomerActions;
