import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Link as RouterLink,
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, Collapse, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { menu } from './Menu';
import { hasChildren } from './Menuutils';

// hooks
import useResponsive from '../../components/common/useResponsive';
// components
// import Logo from '../../components/common/Logo';
import Scrollbar from '../../components/common/Scrollbar';
import NavSection from '../../components/common/NavSection';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 300;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

const MainSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      {/* <Box sx={{ my: 2 }}>
        <Logo /> ARES Menu
      </Box> */}
      <Scrollbar>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {menu.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {menu.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
          </Drawer>
        )}
      </Scrollbar>
    </RootStyle>
  );
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  return <NavSection navConfig={item} />;
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ my: 2 }}>{/* <Logo /> ARES Menu */}</Box>
      <ListItem sx={{ mx: item.pad }} onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse collapsedSize="auto" in={open} timeout="auto" unmountOnExit>
        <List component="div">
          <Divider variant="inset" />
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

MainSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

MenuItem.propTypes = {
  item: PropTypes.object,
};

SingleLevel.propTypes = {
  item: PropTypes.object,
};

MultiLevel.propTypes = {
  item: PropTypes.object,
};

export default MainSidebar;