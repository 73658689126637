import historicalAPI from '../config/axios-common';

const APItype = '/historical';

export const insertHistoricals = async (d) => {
  try {
    delete d.id;
  } catch (e) {
    console.log(e);
  }
  const response = await historicalAPI({ url: `${APItype}/create`, method: 'post', data: d });
  return response;
};

export const bulkInsertHistoricals = async (bulk) => {
  const updatequeries = `?userId=${bulk.uname}`;
  if (bulk.clean) {
    const response = await historicalAPI({
      url: `${APItype}/createBulkFresh${updatequeries}`,
      method: 'post',
      data: bulk.historicals,
    });
    return response;
  }
  const response = await historicalAPI({
    url: `${APItype}/createBulk${updatequeries}`,
    method: 'post',
    data: bulk.historicals,
  });
  return response;
};

export const updateHistoricals = async (d) => {
  const updatequeries = `?id=${d.id}`;
  const response = await historicalAPI({ url: `${APItype}/updateByID${updatequeries}`, method: 'patch', data: d });
  return response;
};

export const deleteHistoricals = async (id) => {
  const response = await historicalAPI({ url: `${APItype}/deleteByID?id=${id}`, method: 'delete' });
  return response;
};

export const getHistoricals = async ({ pageNumber, pageLimit, atrributes = null, stringify = 'false' }) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes !== null) countqueries = `${countqueries}&atrributes=${atrributes}`;
  if (pageLimit) countqueries = `${countqueries}&limit=${pageLimit}`;
  if (pageNumber) countqueries = `${countqueries}&offset=${pageNumber}`;
  const response = await historicalAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getHistoricalsByID = async (id) => {
  const response = await historicalAPI({ url: `${APItype}/findOne?id=${id}`, method: 'get' });
  return response?.data;
};

export const countHistoricals = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await historicalAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const countHistoricalConditional = async ({ stringify = 'false', dimension = null, atrributes = null, period = null, silo = null, customer = null, status = null, favor = null, actiontaken = null, actiontype = null, ownership = null }) => {
  let updatequeries = `?stringify=${stringify}&period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  if (dimension != null) {
    updatequeries = `${updatequeries}&dimension=${dimension}`;
  }
  if (atrributes != null) {
    updatequeries = `${updatequeries}&atrributes=${atrributes}`;
  }
  const response = await historicalAPI({ url: `${APItype}/countAll${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getAllPTMPeriod = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'monthyear').reverse();
};

export const getAllPTMConflicts = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'current_conflicts').reverse();
};

export const getAllPTMReturnedConflicts = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'returned_conflicts').reverse();
};

export const getAllPTMResolved = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthAll${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'current_resolved').reverse();
};

export const getPTMPeriod = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthConflicts${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'monthyear').reverse();
};

export const getPTMConflicts = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthConflicts${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMResolved = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthResolved${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getPTMReturnedConflicts = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/twelvemonthReturnedConflicts${updatequeries}`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'count').reverse();
};

export const getFavorConflicts = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/favorConflicted${updatequeries}`, method: 'get' });
  return response?.data;
};
export const getFavorThirdParty = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/favorThirdPartyResolved${updatequeries}`, method: 'get' });
  return response?.data;
};
export const getFavorAdShare = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/favorAdShareResolved${updatequeries}`, method: 'get' });
  return response?.data;
};
export const getFavorOther = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/favorOther${updatequeries}`, method: 'get' });
  return response?.data;
};

export const getHighestCPNames = async () => {
  const response = await historicalAPI({ url: `${APItype}/toptenCounterParty`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  return arrayColumn(rp, 'counterparty');
};

export const getHighestCPCount = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/toptenCounterParty${updatequeries}`, method: 'get' });
  // eslint-disable-next-line
  return Object.entries(response?.data[0]).map(([key, value]) => {
    return { label: value.label, value: parseInt(value.value, 10) };
  });
};

export const getResolveRadar = async ({ period, silo, customer, status, favor, actiontaken, actiontype, ownership }) => {
  const updatequeries = `?period=${period}&silo=${silo}&cusname=${customer}&status=${status}&favor=${favor}&taken=${actiontaken}&type=${actiontype}&own=${ownership}`;
  const response = await historicalAPI({ url: `${APItype}/actionResolve${updatequeries}`, method: 'get' });
  return response?.data[0];
};

export const getPeriods = async () => {
  const response = await historicalAPI({ url: `${APItype}/allperiods`, method: 'get' });
  const rp = response?.data[0];
  const arrayColumn = (arr, n) =>
    arr.map((x) => {
      return { id: x[n] };
    });
  const finalreturn = arrayColumn(rp, 'monthyear');
  return finalreturn;
};


export default historicalAPI;
