import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';

// sections
import { PieChart } from '../sections/dashboard/templates';

const HistoricalPieChart = ({ useFavorConflicts, useFavorAdShare, useFavorThirdParty, useFavorOther }) => {
  const { data: inconflict } = useFavorConflicts();
  const { data: adshare } = useFavorAdShare();
  const { data: thirdparty } = useFavorThirdParty();
  const { data: other } = useFavorOther();

  const theme = useTheme();

  return (
    <PieChart
      title="Conflict Resolution Share"
      chartData={[
        { label: 'Conflicts', value: parseInt(inconflict, 10) },
        { label: 'Resolved AdShare', value: parseInt(adshare, 10) },
        { label: 'Resolved Third Party', value: parseInt(thirdparty, 10) },
        { label: 'Other', value: parseInt(other, 10) },
      ]}
      chartColors={[
        theme.palette.error.main, // theme.palette.primary.main,
        theme.palette.success.main, // theme.palette.chart.blue[0],
        theme.palette.chart.violet[0],
        theme.palette.chart.yellow[0],
      ]}
    />
  );
}

HistoricalPieChart.propTypes = {
  useFavorConflicts: PropTypes.func,
  useFavorAdShare: PropTypes.func,
  useFavorThirdParty: PropTypes.func,
  useFavorOther: PropTypes.func,
};

export default HistoricalPieChart;