/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// material
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';

// components
import { useUpdateAssetDetail, useDeleteAssetDetail } from '../../services/assetdetailService';




// eslint-disable-next-line
const AssetDetailActions = ({ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }) => {
  const queryClient = useQueryClient();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const editAssetDetailSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['assetdetails-data']);
      setRowId(null);
      setSuccessfulUpdate(true);
      setLoadingUpdate(false);
      setNotify({
        isOpen: true,
        message: 'AssetDetail Update Successful',
        type: 'success',
      });
    }
  };

  const { mutate: updateAssetDetail } = useUpdateAssetDetail(editAssetDetailSuccess);

  const onUpdate = async () => {
    setLoadingUpdate(true);
    // eslint-disable-next-line
    const {
      id,
      created_date,
      last_historical_update,
      main_exist,
      conflict_status,
      approx_daily_views,
      approx_daily_views_in_conflict,
      active_claims,
      ownership_last_updated,
      other_ownership_last_updated,
      ownership_origination,
      customer,
      silo,
      asset_id,
      conflicting_owner,
      conflicting_country_code,
      asset_type,
      custom_id,
      isrc,
      upc,
      artist,
      asset_title,
      album,
      label,
      asset_label,
      constituent_asset_id,
      match_policy,
      is_merged,
      total_views_in_conflict,
      estimated_backpay,
      metadata_origination,
      grid,
    } = params.row;

    await updateAssetDetail({
      id,
      created_date,
      last_historical_update,
      main_exist,
      conflict_status,
      approx_daily_views,
      approx_daily_views_in_conflict,
      active_claims,
      ownership_last_updated,
      other_ownership_last_updated,
      ownership_origination,
      customer,
      silo,
      asset_id,
      conflicting_owner,
      conflicting_country_code,
      asset_type,
      custom_id,
      isrc,
      upc,
      artist,
      asset_title,
      album,
      label,
      asset_label,
      constituent_asset_id,
      match_policy,
      is_merged,
      total_views_in_conflict,
      estimated_backpay,
      metadata_origination,
      grid,
    });

    setLoadingUpdate(false);
  };

  const deleteAssetDetailSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['assetdetails-data']);
      setNotify({
        isOpen: true,
        message: 'Delete AssetDetail Successful',
        type: 'success',
      });
    }
  };

  const { mutate: deleteAssetDetail } = useDeleteAssetDetail(deleteAssetDetailSuccess);

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteAssetDetail(id);
  };

  useEffect(() => {
    if (rowId === params.id && successfulUpdate) setSuccessfulUpdate(false);
    // eslint-disable-next-line
  }, [rowId]);

  return (
    <>
      <Box>
        {successfulUpdate ? (
          <IconButton
            color="primary"
            sx={{
              '&:hover': { bgcolor: green[100] },
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton color="primary" disabled={params.id !== rowId || loadingUpdate} onClick={onUpdate}>
            {rowId !== null ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        )}
        {loadingUpdate && (
          <CircularProgress
            size={51}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
        {
          <IconButton
            color="error"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this record?',
                subTitle: `Record: ${params.id}. You can't undo this operation.`,
                onConfirm: () => {
                  onDelete(params.id);
                },
                onReject: () => {
                  setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                  });
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      </Box>
    </>
  );
};

AssetDetailActions.propTypes = {
  params: PropTypes.object,
  rowId: PropTypes.number,
  setRowId: PropTypes.func,
  setNotify: PropTypes.func,
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func
};

export default AssetDetailActions;
