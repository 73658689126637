import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getAssetDetails,
  insertAssetDetails,
  bulkInsertAssetDetails,
  updateAssetDetails,
  deleteAssetDetails,
  countAssetDetailConditional
} from '../api/assetDetailsAPI';

export const useAssetDetail = (onSuccess, { grade, page, pageSize }) => {
  const thispage = page;
  const thispageSize = pageSize;
  const thisgrade = grade;
  return useQuery(['assetdetails-data', page], () => getAssetDetails({ grade: thisgrade, pageNumber: thispage, pageLimit: thispageSize }), {
    initialData: { rows: [{ id: 0 }] },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: true,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useAddAssetDetail = (addAssetDetailSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((AssetDetail) => insertAssetDetails(AssetDetail), {
    onSuccess: (data) => {
      addAssetDetailSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetdetails-data']);
    },
  });
};

export const useAddAssetDetailBulk = (addAssetDetailSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((AssetDetails) => bulkInsertAssetDetails(AssetDetails), {
    onSuccess: (data) => {
      addAssetDetailSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetdetails-data']);
    },
  });
};

export const useUpdateAssetDetail = (editAssetDetailSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((AssetDetail) => updateAssetDetails(AssetDetail), {
    onSuccess: (data) => {
      editAssetDetailSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetdetails-data']);
    },
  });
};

export const useDeleteAssetDetail = (deleteAssetDetailSuccess) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteAssetDetails(id), {
    onSuccess: (data) => {
      deleteAssetDetailSuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetdetails-data']);
    },
  });
};

export const useCurrentConflicts = () => {
  return useQuery(['conflicts-count'], () => countAssetDetailConditional({ stringify: 'true' }), {
    refetchOnWindowFocus: false,
    // refetchOnMount: true,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};


export const useUpdateCurrentConflicts = () => {
  const queryClient = useQueryClient();
  return useMutation((
    { silo, customer }) => countAssetDetailConditional(
      { stringify: 'true', dimension: '-', silo, customer }), {
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['conflicts-count'], data);
    },
  })
};