import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// components
import { useForm, Form } from '../sections/form/useForm';
import { useCustomerNames } from '../../services/customerService';

import { FormInput, FormSelect, FormDatePicker, FormButton } from '../sections/form/controls';

const siloItems = [
  { id: 'AdShare MG', title: 'AdShare MG' },
  { id: 'AdShare Third Party', title: 'AdShare Third Party' },
];

const historical_ownershipItems = [
  { id: 'Ownership Exists', title: 'Ownership Exists' },
  { id: 'Ownership Removed', title: 'Ownership Removed' },
];

const action_takenItems = [
  { id: 'Pending', title: 'Pending' },
  { id: 'Accepted', title: 'Accepted' },
  { id: 'Rejected', title: 'Rejected' },
  { id: 'Resolved', title: 'Resolved' },
  { id: 'Other', title: 'Other' },
];

const action_typeItems = [
  { id: 'OTR In', title: 'OTR In' },
  { id: 'OTR Out', title: 'OTR Out' },
  { id: 'Email In', title: 'Email In' },
  { id: 'Email Out', title: 'Email Out' },
  { id: 'Other', title: 'Other' },
];

const historical_conflict_statusItems = [
  { id: 'Still in Conflict', title: 'Still in Conflict' },
  { id: 'Resolved', title: 'Resolved' },
];

const resolved_in_favor_ofItems = [
  { id: 'In Conflicted Stage', title: 'In Conflicted Stage' },
  { id: 'Resolved in favor of AdShare', title: 'Resolved in favor of AdShare' },
  { id: 'Resolved in favor of Third Party', title: 'Resolved in favor of Third Party' },
  { id: 'Status Pending', title: 'Status Pending' },
  { id: 'Dummy Resolved', title: 'Dummy Resolved' },
];

const initialFValues = {
  id: 0,
  period: new Date(),
  date_last_updated: new Date(),
  date_created: new Date(),
  historical_conflict_status: '',
  silo: '',
  historical_asset_id: '',
  historical_conflicting_owner: '',
  historical_conflicting_country_code: '',
  resolved_in_favor_of: '',
  historical_customer: '',
  historical_ownership: '',
  action_type: '',
  otr_email_sent_date: new Date(),
  action_taken: '',
  additional_info: '',
  conflicts_manager: '',
};

const HistoricalForm = (props) => {
  // eslint-disable-next-line
  const { addHistorical, historicalNew } = props;
  const initialCustomers = { dataset: [{ id: 'Loading..', title: 'Loading..' }] };
  const [customerList, setCustomerList] = useState(initialCustomers);

  const customernameSuccess = (data) => {
    if (data) {
      const clist = {
        dataset: [
          ...new Set(
            data.map((item) => {
              return { id: item.id, title: item.id };
            })
          ),
        ],
      };
      setCustomerList(clist);
    }
  };

  // eslint-disable-next-line
  const { data: CustomerNames } = useCustomerNames(customernameSuccess);

  const validate = (fieldValues = values) => {
    // MORE VALIDATION REQUIRED, POSTPONED FOR LATER
    /* eslint-disable-next-line prefer-const */
    let temp = { ...errors };
    if ('historical_asset_id' in fieldValues)
      temp.historical_asset_id = fieldValues.historical_asset_id.length > 5 ? '' : 'Please enter a valid Asset ID.';

    if ('historical_conflicting_owner' in fieldValues)
      temp.historical_conflicting_owner =
        fieldValues.historical_conflicting_owner.length > 6 ? '' : 'This Customer Name is too short.';

    if ('historical_conflict_status' in fieldValues)
      temp.historical_conflict_status = fieldValues.historical_conflict_status ? '' : 'This field is required.';

    if ('silo' in fieldValues) temp.silo = fieldValues.silo.length !== 0 ? '' : 'This field is required.';

    if ('historical_asset_id' in fieldValues)
      temp.historical_asset_id = fieldValues.historical_asset_id.length !== 0 ? '' : 'This field is required.';

    if ('historical_conflicting_owner' in fieldValues)
      temp.historical_conflicting_owner =
        fieldValues.historical_conflicting_owner.length !== 0 ? '' : 'This field is required.';

    if ('historical_conflicting_country_code' in fieldValues)
      temp.historical_conflicting_country_code =
        fieldValues.historical_conflicting_country_code.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // eslint-disable-next-line
      addHistorical(values, resetForm);
    }
  };

  useEffect(() => {
    if (historicalNew !== null) {
      // eslint-disable-next-line
      setValues({
        ...historicalNew,
      });
    }
    // eslint-disable-next-line
  }, [historicalNew]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container minWidth="300" width="280">
        <Grid item sm={6}>
          <FormDatePicker
            name="period"
            label="Recorded Date"
            value={values.period}
            error={errors.period}
            onChange={handleInputChange}
          />
          <FormSelect
            name="historical_conflict_status"
            label="Record Status"
            value={values.historical_conflict_status}
            onChange={handleInputChange}
            options={historical_conflict_statusItems}
            error={errors.historical_conflict_status}
          />
          <FormSelect
            name="resolved_in_favor_of"
            label="Current Status"
            value={values.resolved_in_favor_of}
            onChange={handleInputChange}
            options={resolved_in_favor_ofItems}
            error={errors.resolved_in_favor_of}
          />
          <FormDatePicker
            name="date_last_updated"
            label="Last Updated Date"
            value={values.date_last_updated}
            error={errors.date_last_updated}
            onChange={handleInputChange}
          />
          <FormSelect
            name="silo"
            label="Silo"
            value={values.silo}
            onChange={handleInputChange}
            options={siloItems}
            error={errors.silo}
          />
          <FormSelect
            name="action_type"
            label="Action Type"
            value={values.action_type}
            onChange={handleInputChange}
            options={action_typeItems}
            error={errors.action_type}
          />
          <FormSelect
            name="action_taken"
            label="Action Taken"
            value={values.action_taken}
            onChange={handleInputChange}
            options={action_takenItems}
            error={errors.action_taken}
          />
          <FormDatePicker
            name="date_created"
            label="Earliest Conflicted Date"
            value={values.date_created}
            onChange={handleInputChange}
            error={errors.date_created}
          />
        </Grid>
        <Grid item sm={6}>
          <FormInput
            name="historical_asset_id"
            label="Asset ID"
            value={values.historical_asset_id}
            onChange={handleInputChange}
            error={errors.historical_asset_id}
          />
          <FormSelect
            name="historical_customer"
            label="Customer"
            value={values.historical_customer}
            onChange={handleInputChange}
            options={customerList.dataset}
            error={errors.historical_customer}
          />
          <FormInput
            name="historical_conflicting_owner"
            label="Conflicting Owner"
            value={values.historical_conflicting_owner}
            onChange={handleInputChange}
            error={errors.historical_conflicting_owner}
          />
          <FormInput
            name="historical_conflicting_country_code"
            label="Conflicted Territories"
            value={values.historical_conflicting_country_code}
            onChange={handleInputChange}
            error={errors.historical_conflicting_country_code}
          />
          <FormSelect
            name="historical_ownership"
            label="Ownership"
            value={values.historical_ownership}
            onChange={handleInputChange}
            options={historical_ownershipItems}
            error={errors.historical_ownership}
          />

          <FormDatePicker
            name="otr_email_sent_date"
            label="OTR Email Date"
            value={values.otr_email_sent_date}
            onChange={handleInputChange}
            error={errors.otr_email_sent_date}
          />

          <FormInput
            name="additional_info"
            label="Additional Info"
            value={values.additional_info}
            onChange={handleInputChange}
            error={errors.additional_info}
          />
          <FormInput
            name="conflicts_manager"
            label="Conflicts Manager"
            value={values.conflicts_manager}
            onChange={handleInputChange}
            error={errors.conflicts_manager}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <FormButton
              type="submit"
              text="Insert New Record"
              sx={{
                marginTop: 2,
                marginLeft: -12,
                marginRight: 4,
              }}
            />
          </Grid>
          <Grid item>
            <FormButton
              text="Start Over"
              color="grey"
              onClick={resetForm}
              sx={{
                marginTop: 2,
                marginLeft: 8,
                marginRight: 5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

HistoricalForm.propTypes = {
  addHistorical: PropTypes.func,
  historicalNew: PropTypes.object,
};

export default HistoricalForm;