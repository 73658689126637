import rpmAPI from '../config/axios-common';

const APItype = '/estimatedRPM';

export const insertRPMs = async (d) => {
  try {
    delete d.id;
  } catch (e) {
    console.log(e);
  }
  const response = await rpmAPI({ url: `${APItype}/create`, method: 'post', data: d });
  return response;
};

export const bulkInsertRPMs = async (bulk) => {
  const updatequeries = `?userId=${bulk.uname}`;

  console.log({ 'bulk in API': bulk })

  if (bulk.clean) {
    const response = await rpmAPI({
      url: `${APItype}/createBulkFresh${updatequeries}`,
      method: 'post',
      data: bulk.RPMs,
    });
    return response;
  }
  const response = await rpmAPI({
    url: `${APItype}/createBulk${updatequeries}`,
    method: 'post',
    data: bulk.RPMs,
  });
  return response;
};

export const updateRPMs = async (d) => {
  const updatequeries = `?id=${d.id}`;
  const response = await rpmAPI({ url: `${APItype}/updateByID${updatequeries}`, method: 'patch', data: d });
  return response;
};

export const deleteRPMs = async (id) => {
  const response = await rpmAPI({ url: `${APItype}/deleteByID?id=${id}`, method: 'delete' });
  return response;
};

export const getRPMs = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await rpmAPI({ url: `${APItype}/findAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const getRPMsByID = async (id) => {
  const response = await rpmAPI({ url: `${APItype}/findOne?id=${id}`, method: 'get' });
  return response?.data;
};

export const countRPMs = async (stringify = 'false', atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }
  const response = await rpmAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export const countRPMConditional = async (stringify = 'false', dimension = null, atrributes = null) => {
  let countqueries = `?stringify=${stringify}`;
  if (dimension != null) {
    countqueries = `${countqueries}&dimension=${dimension}`;
  }
  if (atrributes != null) {
    countqueries = `${countqueries}&atrributes=${atrributes}`;
  }

  const response = await rpmAPI({ url: `${APItype}/countAll${countqueries}`, method: 'get' });
  return response?.data;
};

export default rpmAPI;
