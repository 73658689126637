import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getBackPays,
  insertBackPays,
  bulkInsertBackPays,
  updateBackPays,
  deleteBackPays,
  getBackPayFeed,
  getBackPayDashboardFeed,
  getPTMNull,
  getPTMArtTrack,
  getPTMAll,
  getPTMMusicVideo,
  getPTMMusic,
  getPTMPeriod,
  getTotalViews,
  getTotalRevenue,
  getTotalUSViews,
  getGrossSold,
  getPeriods
} from '../api/backPayAPI';

export const useBackPay = (onSuccess, { page, pageSize }) => {
  const thispage = page;
  const thispageSize = pageSize;

  return useQuery(['backpay-all-data', page], () => getBackPays({ pageNumber: thispage, pageLimit: thispageSize }), {
    initialData: { rows: [{ id: 0 }] },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useAddBackPay = (addBackPaySuccess) => {
  const queryClient = useQueryClient();

  return useMutation((BackPay) => insertBackPays(BackPay), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addBackPaySuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['backpay-data']);
    },
  });
};

export const useAddBackPayBulk = (addBackPaySuccess) => {
  const queryClient = useQueryClient();

  return useMutation((BackPays) => bulkInsertBackPays(BackPays), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      addBackPaySuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['backpay-data']);
    },
  });
};

export const useUpdateBackPay = (editBackPaySuccess) => {
  const queryClient = useQueryClient();

  return useMutation((BackPay) => updateBackPays(BackPay), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      editBackPaySuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['backpay-data']);
    },
  });
};

export const useDeleteBackPay = (deleteBackPaySuccess) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteBackPays(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      deleteBackPaySuccess(data);
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['backpay-data']);
    },
  });
};

export const useBackPayFeed = (onSuccess) => {
  return useQuery(['backpay-data'], getBackPayFeed, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useBackPayDashboardFeed = (onSuccess) => {
  return useQuery(['backpay-dashboard-data'], getBackPayDashboardFeed, {
    initialData: [{ id: 0 }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useLast12MonthPeriod = () => {
  return useQuery(['ltm-brev-period'], getPTMPeriod, {
    initialData: ['loading 1', 'loading 2'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthPeriod = () => {
  const queryClient = useQueryClient();

  return useMutation(({ period, customer }) => getPTMPeriod({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-period'], data);
    },
  });
};

export const useLast12MonthMusic = () => {
  return useQuery(['ltm-brev-music'], getPTMMusic, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthMusic = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['ltm-brev-music'], basictwelve);

  return useMutation(({ period, customer }) => getPTMMusic({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-music'], data);
    },
  });
};

export const useLast12MonthMusicVideo = () => {
  return useQuery(['ltm-brev-musicvideo'], getPTMMusicVideo, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthMusicVideo = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['ltm-brev-musicvideo'], basictwelve);

  return useMutation(({ period, customer }) => getPTMMusicVideo({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-musicvideo'], data);
    },
  });
};

export const useLast12MonthArtTrack = () => {
  return useQuery(['ltm-brev-arttrack'], getPTMArtTrack, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthArtTrack = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['ltm-brev-arttrack'], basictwelve);

  return useMutation(({ period, customer }) => getPTMArtTrack({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-arttrack'], data);
    },
  });
};

export const useLast12MonthNull = () => {
  return useQuery(['ltm-brev-null'], getPTMNull, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthNull = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['ltm-brev-null'], basictwelve);

  return useMutation(({ period, customer }) => getPTMNull({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-null'], data);
    },
  });
};

export const useTotalView = () => {
  return useQuery(['tot-brev-view'], getTotalViews, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateTotalView = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['tot-brev-view'], 0);

  return useMutation(({ period, customer }) => getTotalViews({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['tot-brev-view'], data);
    },
  });
};

export const useTotalRevenue = () => {
  return useQuery(['tot-brev-rev'], getTotalRevenue, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateTotalRevenue = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['tot-brev-rev'], 0);

  return useMutation(({ period, customer }) => getTotalRevenue({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['tot-brev-rev'], data);
    },
  });
};

export const useTotalUSViews = () => {
  return useQuery(['tot-brev-usview'], getTotalUSViews, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateTotalUSViews = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['tot-brev-usview'], 0);

  return useMutation(({ period, customer }) => getTotalUSViews({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['tot-brev-usview'], data);
    },
  });
};

export const useGrossSold = () => {
  return useQuery(['tot-brev-gross'], getGrossSold, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateGrossSold = () => {
  const queryClient = useQueryClient();
  // queryClient.setQueryData(['tot-brev-gross'], 0);

  return useMutation(({ period, customer }) => getGrossSold({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['tot-brev-gross'], data);
    },
  });
};

export const useLast12MonthAll = () => {
  return useQuery(['ltm-brev-all'], getPTMAll, {
    initialData: ['0', '0'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: () => { },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};

export const useUpdateLast12MonthAll = () => {
  const queryClient = useQueryClient();

  return useMutation(({ period, customer }) => getPTMAll({ period, customer }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
    onSettled: (data) => {
      queryClient.setQueryData(['ltm-brev-all'], data);
    },
  });
};

export const useBackPayMonths = (onSuccess) => {
  return useQuery(['form-backpay-months'], getPeriods, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.error?.forEach((el) =>
          toast.error(el?.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error(error?.data?.message, {
          position: 'top-right',
        });
      }
    },
  });
};