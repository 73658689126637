import { useMemo, useState } from 'react';

// material
import { Button, Box, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SortIcon from '@mui/icons-material/Sort';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import LinearProgress from '@mui/material/LinearProgress';

import { useCSVReader } from 'react-papaparse';

import { useQueryClient } from 'react-query';

// components
import { useCustomer, useAddCustomer, useAddCustomerBulk } from '../../services/customerService';
import Page from '../../components/common/Page';
import CustomerActions from '../../components/customer/customerActions';
import CustomerForm from '../../components/customer/customerForm';
import { useGlobalState } from '../../context/global/globalContext';

// formcomponents
import Popup from '../../components/sections/form/Popup';
import Notification from '../../components/sections/form/Notification';
import ConfirmDialog from '../../components/sections/form/ConfirmDialog';

// ----------------------------------------------------------------------

const Customers = () => {
  const queryClient = useQueryClient();

  const { CSVReader } = useCSVReader();

  const {
    state: { currentUser },
  } = useGlobalState();

  const [pageSize, setPageSize] = useState(25);

  const [rowId, setRowId] = useState(null);

  const [customerNew, setCustomerNew] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const customerSuccess = () => {
    setNotify({
      isOpen: true,
      message: 'Customer Data Refreshed',
      type: 'success',
    });
  };

  const addCustomerSuccess = (data) => {
    if (data) {
      queryClient.invalidateQueries(['customer-data']);
      setCustomerNew(null);
      setOpenPopup(false);
      setNotify({
        isOpen: true,
        message: 'Customer Insert Successful',
        type: 'success',
      });
    }
  };

  const addCustomerSuccessBulk = (data) => {
    if (data) {
      queryClient.invalidateQueries(['customer-data']);
      setNotify({
        isOpen: true,
        message: 'CSV Data Upload Successful',
        type: 'success',
      });
    }
  };

  const { isLoading, data: Customers, isError, error, refetch } = useCustomer(customerSuccess);

  const { mutate: addCustomer } = useAddCustomer(addCustomerSuccess);
  const { mutate: addCustomerBulk } = useAddCustomerBulk(addCustomerSuccessBulk);

  const addCustomerRecord = async (customer, resetForm) => {
    customer.userId = currentUser?.username;
    await addCustomer(customer);
    await resetForm();
  };

  const addCustomerBulkRecords = async (customers, clean) => {
    const uname = currentUser?.username;
    await addCustomerBulk({ customers, clean, uname });
  };

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const resetSort = () => {
    setSortModel([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
  };

  const defaultVisibility = {
    id: false,
    cc_id: true,
    customer: true,
    customer_code_6_digit: true,
    customer_code: true,
    customer_normalized: true,
  };

  const customVisibility = {
    id: false,
    cc_id: true,
    customer: false,
    customer_code_6_digit: true,
    customer_code: false,
    customer_normalized: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultVisibility);
  const [columnVisibilityToggle, setColumnVisibilityToggle] = useState(false);

  useMemo(() => {
    // eslint-disable-next-line
    const newColumns = columnVisibilityToggle === true ? customVisibility : defaultVisibility;
    setColumnVisibilityModel(newColumns);
    // eslint-disable-next-line
  }, [columnVisibilityToggle]);

  const toggleDefaults = () => {
    setColumnVisibilityToggle(!columnVisibilityToggle);
  };

  const csvfileprocess = (results, file) => {
    setConfirmDialog({
      isOpen: true,
      title: `Do you want to upload the file ${file.name}?`,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: true,
          title: `Do you want to remove existing data?`,
          onConfirm: () => {
            addCustomerBulkRecords(results, true);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
          onReject: () => {
            addCustomerBulkRecords(results, false);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          },
        });
      },
      onReject: () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      },
    });
  };

  // CONFIG INFO: https://www.papaparse.com/docs#config
  const parseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: (h) => {
      return h.replace(/ /g, '_').replace(/-/g, '_').replace('/', '_').toLowerCase();
    },
    dynamicTyping: true,
    preview: 0,
    encoding: 'utf-8',
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '  ', '\t', '|', ';'],
  };

  const CustomerHeaders = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        sortable: true,
        filterable: true,
        editable: false,
        disableExport: true,
        type: 'number',
      },
      {
        field: 'cc_id',
        headerName: 'Customer ID',
        width: 120,
        sortable: true,
        filterable: true,
        flex: 1,
        type: 'number',
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://thekraken.godigitalmg.com/networks-customers/customers?search=id&value=${params.row.cc_id}`} target='_blank'>{params.row.cc_id}</a>
        ),
      },
      {
        field: 'customer_code',
        headerName: 'Customer Code (4 Digit)',
        width: 120,
        sortable: true,
        filterable: true,
        flex: 1,
        editable: true,
      },
      {
        field: 'customer_code_6_digit',
        headerName: 'Customer Code (6 Digit)',
        width: 120,
        sortable: true,
        filterable: true,
        flex: 1,
        editable: true,
      },
      {
        field: 'customer',
        headerName: 'Customer Name',
        width: 220,
        sortable: true,
        flex: 1,
        filterable: true,
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://thekraken.godigitalmg.com/networks-customers/customers?search=name&value=${params.row.customer ? params.row.customer.toLowerCase() : ''}`} target='_blank'>{params.row.customer}</a>
        ),
      },
      {
        field: 'customer_normalized',
        headerName: 'Customer Name Normalized',
        width: 220,
        sortable: true,
        flex: 1,
        filterable: true,
        editable: true,
        renderCell: (params) => (
          // eslint-disable-next-line
          <a href={`https://thekraken.godigitalmg.com/networks-customers/customers?search=name&value=${params.row.customer_normalized ? params.row.customer_normalized.toLowerCase() : ''}`} target='_blank'>{params.row.customer_normalized}</a>
        ),
      },
      {
        field: 'actions',
        headerName: 'Options',
        width: 200,
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
        disableExport: true,
        type: 'actions',
        renderCell: (params) => (
          // eslint-disable-next-line
          <CustomerActions {...{ params, rowId, setRowId, setNotify, confirmDialog, setConfirmDialog }} />
        ),
      },
    ],
    // eslint-disable-next-line
    [rowId]
  );

  let content;
  if (isLoading) {
    content = <LinearProgress />;
  } else if (isError) {
    content = (
      <Alert severity="error">
        <AlertTitle>
          Customer Data failed to load. Please logout, clear cache, re-login and try this page again.
        </AlertTitle>
        {error.message}
      </Alert>
    );
  } else {
    return (
      <>
        <Page title="Customers">
          <Box
            sx={{
              height: 700,
              width: '100%',
            }}
          >
            <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
              AdShare Customers
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ maxHeight: '1.8rem', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography color={'error'} variant="body1" sx={{ flexGrow: 1 }}>
                {Customers.length >= 2 ? '' : 'Loading Customer Records. Please wait for a few seconds...'}
              </Typography>

              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                onClick={resetSort}
              >
                Default Sort
              </Button>
              <Button
                variant="outlined"
                startIcon={<VisibilityIcon />}
                onClick={toggleDefaults}
              >
                {columnVisibilityToggle === true ? 'Default View' : 'Compact View'}
              </Button>
              <Button variant="outlined" startIcon={<RefreshSharpIcon />} onClick={refetch}>
                Refresh
              </Button>
              <Button
                variant="outlined"
                startIcon={<PostAddIcon />}
                onClick={() => {
                  setCustomerNew(null);
                  setOpenPopup(true);
                }}
              >
                Add New
              </Button>
              <CSVReader
                config={parseConfig}
                accept="text/csv, .csv, application/vnd.ms-excel"
                onUploadAccepted={(results, file) => csvfileprocess(results, file)}
              >
                {({ getRootProps }) => ( // acceptedFile, ProgressBar, getRemoveFileProps, Remove
                  <>
                    <Button variant="outlined" component="label" startIcon={<DescriptionIcon />} {...getRootProps()}>
                      {' '}
                      Upload CSV
                    </Button>
                  </>
                )}
              </CSVReader>
            </Stack>
            <DataGrid
              columns={CustomerHeaders}
              rows={Customers}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              rowsPerPageOptions={[25, 50, 75, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{ Toolbar: GridToolbar }}
              onCellEditCommit={(params) => setRowId(params.id)}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newVisibilityModel) => setColumnVisibilityModel(newVisibilityModel)}
            />
          </Box>
        </Page>
        <Popup title="Add New Customer Record" openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <CustomerForm customerNew={customerNew} addCustomer={addCustomerRecord} />
        </Popup>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }

  return content;
}

export default Customers;